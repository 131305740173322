import React, { useState, useEffect, Component, ReactDOM } from "react";
import axios from 'axios'
import Header from './Header'
import Footer from './Footer'
import UserOnBoard from './UserOnBoard'
import UserIdea from './UserIdea';
import DetailsPage from './DetailsPage';
import User from "../User";
import packageJson from '../../../package.json';
import SplashScreen from "./SplashScreen";
import Helper from "./Helper";
import InstallPWA from "../prompt/InstallPWA";
import AddToHomescreen from 'react-add-to-homescreen';
import DateSection from "./DateSection";
import MainBodyWrap from "./MainBodyWrap";
import Video from "./Video";
import { ToastBody } from "reactstrap";
import Install_iOS from "../prompt/Install_iOS";

const api = axios.create({ "baseURL": packageJson.proxy });


class MainWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recommendationList: {
                data: [ ],
                options: [
                            // [
                            //     { id: '29232134', title: 'Building', img: '', description: '' }, 
                            //     { id: '29232174', title: 'test', img: '', description: '' }
                            // ],
                        ],
                bio: ''
            },

            theme: 'light',
            recommendation: {},
            processingText: '',
            windowWidth: 0,
            windowHeight: 0,
            loading: false,
            loadingSpinner: false,
            user: {},
            statements: [],
            topics: [],
            detailsPageActive: false,
            userIdeasPageActive: false,
            showFooter: true,
            date: '',
            activeRowId: 0,
            activeIndexId: 0,
            activeIsActive: false,
            videoActive: false,
            videoUrl: '',
            updateUserSearchFunction: false,
            displayByDate: true,
        }

        
        //this.mintIt = this.mintIt.bind(this);
        this.getUser = this.getUser.bind(this);
        this.getSetDate = this.getSetDate.bind(this);
        this.openVideo = this.openVideo.bind(this);
        this.closeVideo = this.closeVideo.bind(this);
        this.switchDisplay = this.switchDisplay.bind(this);
        this.sendStatements = this.sendStatements.bind(this);
        this.updateUserSearch = this.updateUserSearch.bind(this);
        this.finishedUpdatingSearch = this.finishedUpdatingSearch.bind(this);
        this.fetchAIResponseHandler = this.fetchAIResponseHandler.bind(this);
        this.TurnOndetailsPageActive = this.TurnOndetailsPageActive.bind(this);
        this.TurnOffdetailsPageActive = this.TurnOffdetailsPageActive.bind(this);
        this.getRecommendationCallback = this.getRecommendationCallback.bind(this);
        this.getStatementsFromUserIdeas = this.getStatementsFromUserIdeas.bind(this);
        this.handleAddToHomescreenClick = this.handleAddToHomescreenClick.bind(this);
    }

    componentDidMount() {
        
        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            this.setState({ theme: 'dark' });
        }
        else 
        {
            this.setState({ theme: 'light' });
        }
        
        this.setState({ loading: true, processingText: 'fetching...' });
        this.getUser();
        const [ width, height ] = Helper.Window();
        this.state.windowWidth = width;
        this.state.windowHeight = height;

        setTimeout(() => {
            this.fetchAIResponseHandler(() => {
                if (this.state.statements.length == 0) {
                    this.TurnOndetailsPageActive('useridea');
                }
            });
        }, 2000);

        setTimeout(() => {
            if (this.state.statements.length == 0) {
                this.TurnOndetailsPageActive('useridea');
            }
        }, 5000);

        setTimeout(() => {
            this.handleAddToHomescreenClick();
        }, 6000);
    }

     
    getUser() {
        const us = new User();
        const /** @User */ userSet = us.setUser();
        this.setState({ user: userSet });
    }

    handleAddToHomescreenClick() {

    };

    TurnOndetailsPageActive(component) {
        
        // const footer = document.querySelector(".updateUser");
        // footer.focus();

        if (component == 'recommend') {
            this.setState({ 
                detailsPageActive: true, 
                showFooter: false, 
                userIdeasPageActive: false 
            });
        }  

        if (component == 'useridea') {
            this.setState({ 
                userIdeasPageActive: true, 
                showFooter: true, 
                detailsPageActive: false 
            });
        }  
    }

    TurnOffdetailsPageActive() {
        this.setState({ 
            showFooter: true, 
            userIdeasPageActive: false,  
            detailsPageActive: false  
        });

        // const footer = document.querySelector(".updateUser");
        // footer.focus();
    }

    getRecommendationCallback(recommendation, row, index)  {
        this.setState({ 
            recommendation: recommendation, 
            activeRowId: row, 
            activeIndexId: index
        });
    }

    switchDisplay() {
        if (this.state.displayByDate) {
            this.setState({ displayByDate: false });
        }
        else 
        {
            this.setState({ displayByDate: true});
        }

        this.fetchAIResponseHandler();
    }

    updateUserSearch() {
        this.setState({ updateUserSearchFunction: true });  
    }

    finishedUpdatingSearch() {
        this.setState({ updateUserSearchFunction: false });
    }

    windowSetIt() {
        console.log('width log', localStorage['windowSize_mobile_width']);
        this.setState({ windowWidth: localStorage['windowSize_mobile_width'], windowHeight: localStorage['windowSize_mobile_height'] });
    }

    getStatementsFromUserIdeas(statements, topics) {
        this.setState({ statements: statements, topics: topics });
        if (this.state.statements.length == 0 || this.state.topics.length == 0) {
            this.TurnOndetailsPageActive('useridea');
        }
    }

    openVideo(url) {
        this.setState({ videoUrl: url, videoActive: true });
    }

    closeVideo() {
        this.setState({ videoActive: false });
    }

    getSetDate(date) {
        this.setState({ date: date });
    }

    sendStatements() {
        return this.state.statements;
    }

    fetchAIResponseHandler(callback) {
        let axiosConfig = {
          headers: {
             'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                "Host": "https://us-central1-cofounderapi.cloudfunctions.net/",
                "Origin": "https://cofounderaiai.com",
                "Referer": "https://us-central1-cofounderapi.cloudfunctions.net/"
            }
        };

        const userset = JSON.stringify(this.state.user);
        this.setState({ processingText: "fetching...", loadingSpinner: true });
        console.log('statements', this.state.statements)

        api({
            method: 'post',
            url: "/fetch-ai-ideas",
            headers: axiosConfig,
            data: { user: userset },
        }).then(response => { 
            console.log(response.data.data);

            let optionsData = [];
            let optionDates = [];
            let mapData = [];
            let purePlans = [];
            let scorePlans = [];
            let scorePlanList = []
            response.data.data?.forEach(row => {
                optionsData.push(row.data);
                optionDates.push(row);
                
                row.data.map(x => {
                    mapData.push({ id: x.id, plan: x.title.replace("\n\n", ". "), roi: x.opportunityCost, description: x.description.replace("\n\n", ". "), commited: x.commit.Commited });
                    purePlans.push(x.title.replace("\n\n", ". "));
                    if (x.overallROI === undefined) {
                        x.overallROI = Math.abs(3 * x.perspectiveROI * 1 * x.recommendation.opportunityCost);
                    }

                    x.overallROI = Math.abs(x.overallROI);
                    
                    scorePlans.push(x)
                });     
            });

            console.log(purePlans);
            console.log(mapData);
            console.log(optionsData);

            let recommendation = {
                options: optionsData,
                date: '',
                bio: '',
                data: optionDates
            }

            if (! this.state.displayByDate) {
                scorePlans.sort((a, b) => parseFloat(b.overallROI) - parseFloat(a.overallROI));
                scorePlanList = scorePlans.map(x => { return [x]});
                recommendation.options = scorePlanList;
            }
            else 
            {
                recommendation.options = optionsData;
            }

            console.log("all day my guy", response.data);

            this.setState({ processingText: "" });
            this.setState({ recommendationList: recommendation, loading: false, loadingSpinner: false });  

            if (callback !== undefined) {
                callback();
            }
        });
    }

    

    render() {
        const user = JSON.parse(localStorage['user'])
        const uid = user != null ? user.uid: '';
        const userData = user != null ? user: {};

        console.log('top date: ', this.state.date)
    
        if (user != null) {
            return (<div className="main-section-view" style= {{ height: this.state.windowHeight }}>
                    <div>
                    {console.log(this.state.recommendationList)}
                    <Install_iOS  theme={this.state.theme} />
                    <SplashScreen 
                        loading={this.state.loading}
                        theme={this.state.theme}
                    />
                    <Header 
                        fetchAIResponseHandler = {this.fetchAIResponseHandler}
                        loading={this.state.loadingSpinner}
                        theme={this.state.theme}
                        switchDisplay = {this.switchDisplay}
                    />
                    <Video 
                        video = { this.state.videoUrl }
                        active = {this.state.videoActive}
                        theme={this.state.theme}
                        openVideo = {this.openVideo}
                        closeVideo = {this.closeVideo}
                    />
                    <DateSection 
                        getSetDate={this.getSetDate}
                        theme={this.state.theme}
                        displayByDate = {this.state.displayByDate}    
                    />
                    <MainBodyWrap
                        theme={this.state.theme}
                        recommendationList = {this.state.recommendationList} 
                        recommendation={this.state.recommendation} 
                        displayByDate={this.state.displayByDate}
                        TurnOndetailsPageActive = {this.TurnOndetailsPageActive} 
                        getRecommendationCallback = {this.getRecommendationCallback} 
                        statements = {this.state.statements}
                        date = {this.state.date}
                    />
                    <Footer 
                        theme={this.state.theme}
                        statements = {this.state.statements}
                        topics = {this.state.topics}                        
                        showFooter = {this.state.showFooter}
                        fetchAIResponseHandler = {this.fetchAIResponseHandler}
                        sendStatements = {this.sendStatements}
                        updateUserSearch = {this.updateUserSearch}
                        TurnOndetailsPageActive = {this.TurnOndetailsPageActive}
                        TurnOffdetailsPageActive = {this.TurnOffdetailsPageActive}
                        mintIt = {minting => this.mintIt = minting}
                    />
                    <UserIdea
                        theme={this.state.theme}
                        active = {this.state.userIdeasPageActive} 
                        recommendation = {this.state.recommendation}
                        windowWidth = {this.state.windowWidth}
                        windowHeight = {this.state.windowHeight}
                        updateUserSearchFunction = {this.state.updateUserSearchFunction}
                        finishedUpdatingSearch = {this.finishedUpdatingSearch}
                        getStatementsFromUserIdeas = {this.getStatementsFromUserIdeas}
                        TurnOffdetailsPageActive = {this.TurnOffdetailsPageActive}
                        mintIt = {this.mintIt}
                    />
                    <DetailsPage 
                        theme={this.state.theme}
                        active = {this.state.detailsPageActive}
                        index = {this.state.activeIndexId} 
                        row = {this.state.activeRowId}
                        recommendation = {this.state.recommendation} 
                        openVideo = {this.openVideo}
                        TurnOffdetailsPageActive = {this.TurnOffdetailsPageActive}
                        TurnOndetailsPageActive = {this.TurnOndetailsPageActive}
                        fetchAIResponseHandler = {this.fetchAIResponseHandler}
                        getRecommendationCallback = {this.getRecommendationCallback}
                    /> 
                    </div>
                </div>);
        }
        else 
        {
            return <div></div>;
        }
    }
}

export default MainWrapper;