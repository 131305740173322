import React, { useState, useEffect, Component } from "react";
import MainBody from './MainBody'

class MainBodyWrap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayBox: false,
            displayBoxStyle: 'none',
            pStart: { x: 0, y: 0 },
            pStop: { x: 0, y: 0 }
        }

        this.swipeStart = this.swipeCheck.bind(this);
        this.swipeEnd = this.swipeEnd.bind(this);
        this.swipeCheck = this.swipeCheck.bind(this);
        this.isPullDown = this.isPullDown.bind(this);
    }


    swipeStart(e) {
        if (typeof e["targetTouches"] !== "undefined") {
            var touch = e.targetTouches[0];
            let pStart = { x: 0, y: 0 };
            pStart.x = touch.screenX;
            pStart.y = touch.screenY;

            this.setState({ pStart: pStart });
        } else {
            let pStart = { x: 0, y: 0 };
            pStart.x = e.screenX;
            pStart.y = e.screenY;
            this.setState({ pStart: pStart });
        }
    }

    swipeEnd(e) {
        if (typeof e["changedTouches"] !== "undefined") {
            var touch = e.changedTouches[0];
            let pStop = { x: 0, y: 0 };
            pStop.x = touch.screenX;
            pStop.y = touch.screenY;
            this.setState({ pStop: pStop });
        } else {
            let pStop = { x: 0, y: 0 };
            pStop.x = e.screenX;
            pStop.y = e.screenY;
            this.setState({ pStop: pStop });
        }

        this.swipeCheck();
    }

    swipeCheck() {
        var changeY = this.state.pStart.y - this.state.pStop.y;
        var changeX = this.state.pStart.x - this.state.pStop.x;
        if (this.isPullDown(changeY, changeX)) {
            alert("Swipe Down!");
        }
    }

    isPullDown(dY, dX) {
        // methods of checking slope, length, direction of line created by swipe action
        return (
          dY < 0 &&
          ((Math.abs(dX) <= 100 && Math.abs(dY) >= 300) ||
            (Math.abs(dX) / Math.abs(dY) <= 0.3 && dY >= 60))
        );
    }
    

    render() {
        return (<div>
            <MainBody  
                recommendationList = {this.props.recommendationList} 
                recommendation={this.props.recommendation} 
                TurnOndetailsPageActive = {this.props.TurnOndetailsPageActive} 
                getRecommendationCallback = {this.props.getRecommendationCallback} 
                statements = {this.props.statements}
                date={this.props.date}
            />
        </div>
        );
    }
}

export default MainBodyWrap;