import React, { useState, useEffect, Component } from "react";
import User from "../User";
import axios from 'axios'
import packageJson from '../../../package.json';
import userData from '../../userData.json';
import userDataAdmin from '../../userDataAdmin.json';
import userDataPremium from '../../userDataPremium.json';
import JSConfetti from 'js-confetti'

const api = axios.create({ "baseURL": packageJson.proxy });

class UserIdea extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            display: 'none',
            active: false,
            oldStatement: '',
            percentageComplete: 0,
            backgroundColor: '#ff801e',
            statements: [],
            nonTopicStatements: [],
            computeStatements: [],
            topics: [],
            loading: false, 
            showMessage: false,
            confettiOnLoad: false,
            confettiOnTargetCount: false,
            firstTimeFetch: false,
            previousStatementLength: 0,
            messageDisplayCss: '',         
            messageDisplay: 'How are you telling today?',
            values: [], 
            processingText: "",
            user: {},
            lookupData: {},
            lookupActiveData: [],
            currentStatementMaxCount: 1
        }

        this.exit = this.exit.bind(this);
        this.confetti = this.confetti.bind(this);
        this.privateConfetti = this.privateConfetti.bind(this);
        this.SearchList = this.SearchList.bind(this);
        this.ClearSearch = this.ClearSearch.bind(this);
        this.clickStatement = this.clickStatement.bind(this);
        this.editStatmentsHandler = this.editStatmentsHandler.bind(this);
        this.fetchStatmentsHandler = this.fetchStatmentsHandler.bind(this);
        this.update = this.update.bind(this);
        this.getUser = this.getUser.bind(this);
        this.autoSetupInput = this.autoSetupInput.bind(this);
        this.makeBoxActive = this.makeBoxActive.bind(this);
        this.countMaxStatements = this.countMaxStatements.bind(this);
        this.user = this.getUser()
        this.noList = [ ".", ",", "?", ";", ":", "'", '"', "<", ">", "=", "-", "(", "*", "&", "^", "$", "#", "@", "!", "~", "`", "+", "_" ];
        this.maxStatementCountList = [ 6, 10 ]
        this.options = [
            'How are you feeling Today?',
            "Update your goal so we can help you",
            "We want you to get ahead",
            "Everyday is a new beginning",
            "What do you wish you could have",
            "Every morning, remember it's a new day",
            "We can figure it out let's go.",
            '"The struggle makes everything else easier. The harder you make it, the easier the world will be." - Frank Frisby',
            '"The greatest glory in living lies not in never falling, but in rising every time we fall." -Nelson Mandela',
            '"The way to get started is to quit talking and begin doing." -Walt Disney',
            '"' + "our time is limited, so don't waste it living someone else's life. Don't be trapped by dogma – which is living with the results of other people's thinking." + '" -Steve Jobs',
            '"If life were predictable it would cease to be life, and be without flavor." -Eleanor Roosevelt',
            '"' + "If you look at what you have in life, you'll always have more. If you look at what you don't have in life, you'll never have enough." + '" -Oprah Winfrey',
            '"' + "If you set your goals ridiculously high and it's a failure, you will fail above everyone else's success." + '" -James Cameron',
            '"' + "Life is what happens when you're busy making other plans." + '" -John Lennon',
            '"Spread love everywhere you go. Let no one ever come to you without leaving happier." -Mother Teresa',
            '"When you reach the end of your rope, tie a knot in it and hang on." -Franklin D. Roosevelt',
            '"Always remember that you are absolutely unique. Just like everyone else." -Margaret Mead',
            '"' + "Don't judge each day by the harvest you reap but by the seeds that you plant." + '" -Robert Louis Stevenson',
            '"The future belongs to those who believe in the beauty of their dreams." -Eleanor Roosevelt',
            '"Tell me and I forget. Teach me and I remember. Involve me and I learn." -Benjamin Franklin',
            '"The best and most beautiful things in the world cannot be seen or even touched — they must be felt with the heart." -Helen Keller',
            '"It is during our darkest moments that we must focus to see the light. -Aristotle',
            '"Do not go where the path may lead, go instead where there is no path and leave a trail." -Ralph Waldo Emerson',
            '"If life were predictable it would cease to be life and be without flavor." -Eleanor Roosevelt',
            '"' + "In the end, it's not the years in your life that count. It's the life in your years." + '" -Abraham Lincoln',
            '"Life is a succession of lessons which must be lived to be understood." -Ralph Waldo Emerson',
            '"You will face many defeats in life, but never let yourself be defeated." -Maya Angelou',
            '"Never let the fear of striking out keep you from playing the game." -Babe Ruth',
            '"The only impossible journey is the one you never begin." -Tony Robbins',
            '"In this life we cannot do great things. We can only do small things with great love." -Mother Teresa',
            '"Only a life lived for others is a life worth while." -Albert Einstein',
            '"The purpose of our lives is to be happy." -Dalai Lama',
            '"You only live once, but if you do it right, once is enough." -Mae West',
            '"Life is really simple, but we insist on making it complicated." -Confucius',
            '"May you live all the days of your life." -Jonathan Swift',
            '"Life itself is the most wonderful fairy tale." -Hans Christian Andersen',
            '"Do not let making a living prevent you from making a life." -John Wooden',
            '"Life is a long lesson in humility." -James M. Barrie',
            '"' + "I was poor because I didn't have any thing. But I was rich because I had a dream." + '" - Arnold Schwarteneggar',
            '"Love the life you live. Live the life you love." -Bob Marley',
            '"' + "In three words I can sum up everything I've learned about life: it goes on." + '" -Robert Frost',
            '"Life is either a daring adventure or nothing at all." -Helen Keller',
            '"Life is made of ever so many partings welded together." -Charles Dickens',
            '"' + "Your time is limited, so don't waste it living someone else's life. Don't be trapped by dogma — which is living with the results of other people's thinking." + '" -Steve Jobs',
            '"Life is trying things to see if they work." -Ray Bradbury',
            '"' + "Many of life's failures are people who did not realize how close they were to success when they gave up." + '" -Thomas A. Edison',
            '"You have brains in your head. You have feet in your shoes. You can steer yourself any direction you choose." -Dr. Seuss',
            '"Success usually comes to those who are too busy to be looking for it." -Henry David Thoreau',
            '"Success is not final; failure is not fatal: It is the courage to continue that counts." -Winston S. Churchill',
            '"The way to get started is to quit talking and begin doing." -Walt Disney',
            '"If you really look closely, most overnight successes took a long time." -Steve Jobs',
            '"The secret of success is to do the common thing uncommonly well." -John D. Rockefeller Jr.',
            '"I find that the harder I work, the more luck I seem to have." -Thomas Jefferson',
            '"' + "The real test is not whether you avoid this failure, because you won't. It's whether you let it harden or shame you into inaction, or whether you learn from it; whether you choose to persevere." + '" -Barack Obama',
            '"The secret of success is to do the common thing uncommonly well." -John D. Rockefeller Jr.',
            '"I find that the harder I work, the more luck I seem to have." -Thomas Jefferson',
            '"Success is not final; failure is not fatal: It is the courage to continue that counts." -Winston S. Churchill',
            '"' + "Don't be distracted by criticism. Remember — the only taste of success some people get is to take a bite out of you." + '" -Zig Ziglar',
            '"I never dreamed about success, I worked for it." -Estee Lauder',
            '"' + "Success seems to be connected with action. Successful people keep moving. They make mistakes but they don't quit." + '" -Conrad Hilton',
            '"Success usually comes to those who are too busy to be looking for it." -Henry David Thoreau',
            '"There are no secrets to success. It is the result of preparation, hard work, and learning from failure." -Colin Powell',
            '"' + "The real test is not whether you avoid this failure, because you won't. It's whether you let it harden or shame you into inaction, or whether you learn from it; whether you choose to persevere." + '" -Barack Obama',
            '"The only limit to our realization of tomorrow will be our doubts of today." -Franklin D. Roosevelt',
            '"It is better to fail in originality than to succeed in imitation." -Herman Melville',
            '"' + "Successful people do what unsuccessful people are not willing to do. Don't wish it were easier; wish you were better." + '" -Jim Rohn',
            '"I failed my way to success." -Thomas Edison',
            '"The road to success and the road to failure are almost exactly the same." -Colin R. Davis',
            '"If you really look closely, most overnight successes took a long time." -Steve Jobs',
            '"A successful man is one who can lay a firm foundation with the bricks others have thrown at him." -David Brinkley',
            '"Things work out best for those who make the best of how things work out." -John Wooden',
            '"Try not to become a man of success. Rather become a man of value." -Albert Einstein',
            '"' + "Don't be afraid to give up the good to go for the great." + '" -John D. Rockefeller',
            '"Always bear in mind that your own resolution to success is more important than any other one thing." -Abraham Lincoln',
            '"Success is walking from failure to failure with no loss of enthusiasm." -Winston Churchill',
            '"You know you are on the road to success if you would do your job and not be paid for it." -Oprah Winfrey',
            '"' + "If you genuinely want something, don't wait for it — teach yourself to be impatient." + '" -Gurbaksh Chahal',
            '"If you want to achieve excellence, you can get there today. As of this second, quit doing less-than-excellent work." -Thomas J. Watson',
            '"If you are not willing to risk the usual, you will have to settle for the ordinary." -Jim Rohn',
            '"The only place where success comes before work is in the dictionary." -Vidal Sassoon',
            '"' + "You miss 100% of the shots you don't take." + '" -Wayne Gretzky',
            '"' + "Whether you think you can or you think you can't, you're right." + '" -Henry Ford',
            '"' + "I have learned over the years that when one's mind is made up, this diminishes fear." + '" -Rosa Parks',
            '"I alone cannot change the world, but I can cast a stone across the water to create many ripples." -Mother Teresa',
            '"' + "The question isn't who is going to let me; it's who is going to stop me." + '" -Ayn Rand',
            '"The only person you are destined to become is the person you decide to be." -Ralph Waldo Emerson',
            '"' + "If you're offered a seat on a rocket ship, don't ask what seat! Just get on." + '" -Sheryl Sandberg',
            '"' + "I didn't fail the test. I just found 100 ways to do it wrong." + '" -Benjamin Franklin',
            '"' + "If you look at what you have in life, you'll always have more. If you look at what you don't have in life, you'll never have enough." + '" -Oprah Winfrey',
            '"Dreaming, after all, is a form of planning." -Gloria Steinem',
            '"Whatever the mind of man can conceive and believe, it can achieve." -Napoleon Hill',
            '"' + "Twenty years from now you will be more disappointed by the things that you didn't do than by the ones you did do. So, throw off the bowlines, sail away from safe harbor, catch the trade winds in your sails. Explore, Dream, Discover."  + '" -Mark Twain',
            '"First, have a definite, clear practical ideal; a goal, an objective. Second, have the necessary means to achieve your ends; wisdom, money, materials, and methods. Third, adjust all your means to that end." -Aristotle'
        ]
    }


    componentDidMount() {
        this.setState({ loading: true, processingText: 'fetching...' });
        this.getUser();
        this.autoSetupInput();

        let index = Math.floor(Math.random() * this.options.length);

        if (index < 0) {
            index = 0;
        }

        this.setState({ messageDisplay: this.options[index] });

        setTimeout(() => {
            this.fetchStatmentsHandler();
            this.getUser();
            this.autoSetupInput();
            // setTimeout(() => {
            //     this.fetchStatmentsHandler();
            //     this.getUser();
            //     this.autoSetupInput();
            // }, 1000);
        }, 3000);
    }
    
    exit() {
        this.setState({ display: 'none', active: false });
        this.props.TurnOffdetailsPageActive();
        this.ClearSearch();
    }

    

    confetti(show) {
        let target = show.targetCount != undefined? true : false;
        let load = show.onLoad != undefined? true : false;

        if (this.state.active && load && ! this.state.confettiOnLoad) {
            this.privateConfetti();
            this.setState({ confettiOnLoad: true });
        }

        if (this.state.active && target && ! this.state.confettiOnTargetCount) {
            this.privateConfetti();
            this.setState({ confettiOnTargetCount: true });
        }
    }

    privateConfetti() {
        const canvas = document.querySelector("#user-on-board");
        const jsConfetti = new JSConfetti({ canvas });
        jsConfetti.addConfetti({
            confettiColors: [
            '#ff801e', '#3d663c', '#409d3d', '#3f4850', '#e6e933', '#fd5034', '#178cdf'  
            ],
        });
    }

    getUser() {
        const us = new User();
        us.setUser();
        const /** @User */ userSet = us.getUser();
        //alert(userSet.role);
        this.setState({ user: userSet });
        return userSet;
    }

    clickStatement(e) {
        const current = e.target.value;
        this.setState({ oldStatement: current });
    }

    autoSetupInput() {
        const stateElms = document.querySelectorAll(".state");
        let values = [];
        stateElms.forEach(elm => {
            values.push({ 
                id: elm.getAttribute('data-id'),
                statement: elm.value 
            });
        });


        let fullData = userData;
        switch(this.user.role) {
            case this.user.roles.admin:
                fullData = userDataAdmin;
                break;
            case this.user.roles.premium:
                fullData = userDataPremium;
                break;
            case this.user.roles.base:
                fullData = userData;
                break;
            default:
                fullData = userData;
        }

        this.setState({ values: values, lookupData: fullData });
    }

    SearchList(e) {
        let val = e.target.value != undefined ? e.target.value : e.target.value.toLowerCase();
        const lookups = document.querySelectorAll(".lookup");

        lookups.forEach(x => {
            let elm = x.querySelector(".child-item");
            let text = elm.textContent.toLowerCase();
            if (val == "" || text.includes(val.toLowerCase())) {
                x.style.display = "";
                x.classList.remove("hidden");
            }
            else 
            {
                x.style.display = 'none';
                x.classList.add("hidden");
            }
        });

        const lookupSection = document.querySelectorAll(".lookup-section");

        lookupSection.forEach(x => {
            let elms = x.querySelectorAll(".child-item");
            let hiddens = x.querySelectorAll(".hidden");

            if (elms.length == hiddens.length) {
                x.style.display = "none";
            }
            else 
            {
                x.style.display = "";
            }
        });
    }

    ClearSearch() {
        const search = document.querySelector(".search");
        search.value = '';

        const lookups = document.querySelectorAll(".lookup");
        lookups.forEach(x => {
            x.style.display = "";
            x.classList.remove("hidden");
        });

        const lookupSection = document.querySelectorAll(".lookup-section");

        lookupSection.forEach(x => {
            x.style.display = "";
        });
    }

    makeBoxActive(e) {
        if (!e.target.classList.contains('active')) {
            e.target.classList.add('active');
            e.target.classList.add('hasid');            
            e.target.style.backgroundColor = "#0f73ad";
            e.target.style.color = "#fff";
        }
        else 
        {
            e.target.classList.remove('active');
            e.target.classList.add('remove');
            e.target.classList.remove('hasid');  
            e.target.style.backgroundColor = "#1b272e";
            e.target.style.color = "#d2d4d8";
        }

        this.update();
        this.editStatmentsHandler(e);
    }

    editStatmentsHandler(e) {
        const currentStatement = e.target.value;
        if (this.state.oldStatement == currentStatement) {
            console.log('current state did not change');
            return;
        } 

        if (this.state.percentageComplete > 1) {
            return;
        }

        console.log('haha go');

        this.update();

        let statementList = [];
        const userid = localStorage['userid'];
        let axiosConfig = {
          headers: {
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                "Host": "https://us-central1-cofounderapi.cloudfunctions.net/",
                "Origin": "https://cofounderaiai.com",
                "Referer": "https://us-central1-cofounderapi.cloudfunctions.net/"
            }
        };

        let valueset = [];
        if (e.target.classList.contains('hasid')) {
            let item = e.target.querySelector('.child-item');

            console.log(item);
            valueset.push({ 
                id: e.target.getAttribute('data-id'),
                statement: item.textContent 
            });
        }

        if (e.target.classList.contains('remove')) {
            valueset.push({ 
                id: e.target.getAttribute('data-id'),
                statement: ''
            }); 
        }

        if (e.target.classList.contains('state')) {
            if (e.target.value != "" && (e.target.value.length < 6 || (e.target.value.split(" ")).length < 3)) {
                alert("😳Do me a favor and fill in a full sentence please.");
                return;
            }

            valueset.push({ 
                id: e.target.getAttribute('data-id'),
                statement: e.target.value 
            });
        }
        
        valueset.forEach(x => {
            if (x.id != '' || x.statement != '') {
                statementList.push(x);
            }
        })

        api({
            method: 'post',
            url: "/edit-thought",
            headers: axiosConfig,
            data: { statements: JSON.stringify(statementList), user: JSON.stringify(this.state.user) }
        }).then(response => { 
            this.fetchStatmentsHandler();
        });
    }

    getSelectedTopicsAndGoals() {
        const elements = document.querySelectorAll(".lookup-section-topic");

        let topics = [];
        let Goals = [];
        elements.forEach((elm, i) => {
            const elms = elm.querySelectorAll(".lookup.active")
            elms.forEach((row, j) => {
                if (i == 0) {
                    topics.push(row.textContent);
                }

                if (i == 1) {
                    Goals.push(row.textContent);
                }
            });
        });

        return [topics, Goals];
    }


    fetchStatmentsHandler() {
        let axiosConfig = {
          headers: {
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                "Host": "https://us-central1-cofounderapi.cloudfunctions.net/",
                "Origin": "https://cofounderaiai.com",
                "Referer": "https://us-central1-cofounderapi.cloudfunctions.net/"
            }
        };

        const userset = JSON.stringify(this.state.user);
        const states = document.querySelectorAll(".state");
        const currentStatementLength = this.state.statements.length;
        this.setState({ processingText: "fetching...", previousStatementLength: currentStatementLength });

        api({
            method: 'post',
            url: "/fetch_thoughts",
            headers: axiosConfig,
            data: { user: userset },
        }).then(response => { 
            let obj = response.data;
            localStorage['userFromData'] = JSON.stringify(response.data.user);
            this.getUser();
            this.autoSetupInput();

            let stateElms = obj.data;

            stateElms = stateElms.sort((a, b) => b.timestamp - a.timestamp)

            let statesk = [];
            stateElms.forEach(elm => {
                if (elm.isActive) {
                    statesk.push(elm);
                }
            });

            stateElms = statesk;

            let newStatements = [];
            let values = [];

            stateElms.forEach(elm => {
                if (elm.isActive) {
                    values.push({ 
                        id: elm.id,
                        statement: elm.statement.trim()
                    });
                }
            });

            console.log(values);


            values = values.map(x => {
                if (x.id != '' || x.statement.trim() != '') {
                    return x;
                }
            });

            let dontTypeList = [];
            let lookupActiveData = [];  
            const lookupDataList = document.querySelectorAll(".lookup");
            console.log("log it:", lookupDataList.length);
            lookupDataList.forEach((elmi, index) => {
                let elm = elmi.querySelector('.child-item');
                values.forEach(x => {
                    if (elm.textContent.toLowerCase() == x.statement.toLowerCase() && x.statement.trim() != ""){
                        dontTypeList.push(x.id);
                        lookupActiveData.push(x);
                        newStatements.push(x.statement.trim());
                    }
                });
            });

            this.setState({ lookupActiveData: lookupActiveData });


            let count = 0;
            stateElms.forEach(elm => {
                if (elm.statement.trim() != '') {
                    count++;
                }
            });

   

            // This section we are adding to exising buttons of user data
            const rows = document.querySelectorAll(".state");
            rows.forEach((row, idx) => {
                row.value = '';
                row.innerHTML = '';
                row.setAttribute('data-id', '');
            });

            let localIndex = 0;
            
            let computeStatements = [];
            let nonTopicStatements = [];
            let topics = [];
            
            stateElms.map((r, i) => {
                if (i < 5) {
                    computeStatements.push(r.statement);
                }
            });


            let activeTopics = this.state.lookupActiveData.map(x => { return  x.statement.toLowerCase() });
            values.forEach(x => {
                if (!activeTopics.includes(x.statement.trim())){
                    nonTopicStatements.push(x);
                }
            });

            this.setState({ nonTopicStatements: nonTopicStatements });

            let noncount = 0;
            nonTopicStatements.map((x, i) => {
                let words = x.statement.trim().split(" ");
                if (x.statement.trim() != "" && noncount < 10 && words.length >= 3)  {
                    computeStatements.push(x.statement);
                    noncount++;
                }
            })

            values.map((item, i) => {  
                try {                     
                    if (!dontTypeList.includes(item.id) && item.statement.trim() != "" && i < 8) {
                        let elm = rows[localIndex];
                        console.log(elm);
                        elm.innerHTML = item.statement;
                        elm.value = item.statement;
                        elm.setAttribute('data-id', item.id);
                        newStatements.push(item.statement);
                        localIndex++;
                        computeStatements.push(item.statement);
                    }
                }
                catch(e) {

                }
            });

            let remain = states.length - values.length;
            if (remain < 0) remain = 0;
            
            Object.keys(this.state.lookupData).map((mainKey, j) => {
                Object.keys(this.state.lookupData[mainKey]).map((key, index) => {
                    
                    // get the base data set
                    if (j == 0) {
                        let statements = this.state.lookupActiveData.map(x => { return x.statement.toLowerCase() });
                        this.state.lookupData[mainKey][key].map(x => { 
                            if (statements.includes(x.toLowerCase())) {                   
                                topics.push(x.toLowerCase())  
                            }
                        })
                    }
                    if (j > 0) {
                        let heading = key.replace("_", " ");
                        heading = heading.charAt(0).toUpperCase() + heading.slice(1);
                        let statements = this.state.lookupActiveData.map(x => { return x.statement.toLowerCase() });
                        this.state.lookupData[mainKey][key].map(x => {
                            if (statements.includes(x.toLowerCase())) {                   
                                computeStatements.push(heading.toLowerCase() + " " + x.toLowerCase())  
                            }
                        })
                    }
                });
            });

            computeStatements = computeStatements.slice(0, 7);

            //console.log(topics.join("|"))

            if (!this.state.firstTimeFetch) {
                this.countMaxStatements(newStatements);
            }

            if (this.state.active) {              
                if (newStatements.length == 0) {
                    this.confetti({ onLoad: true });
                }
            }
            
            // stops doing all of the first time fetching now that it's off.
            if (!this.state.firstTimeFetch) {
                this.setState({ firstTimeFetch: true });
            }

            let percentageComplete = count / this.state.currentStatementMaxCount > 1 ? 1 : count / this.state.currentStatementMaxCount; // then number of total textb boxes

            if (percentageComplete > 0.95) {
                this.setState({ backgroundColor: "#327e0b"});
            }
            else 
            {
                this.setState({ backgroundColor: "#ff801e"});
            }


            console.log(computeStatements);
            this.setState({ computeStatements: computeStatements, topics: topics });
            this.setState({ processingText: "", percentageComplete: percentageComplete });
            this.setState({ statements: newStatements,  loading: false, values: values, percentageComplete: percentageComplete, processingText: "" });
            this.props.getStatementsFromUserIdeas(computeStatements, topics);

                            
            if (newStatements.length == this.state.currentStatementMaxCount && this.state.active) {
                this.confetti( { targetCount: true });
                this.props.mintIt();
                this.exit();
            }

            if (topics.length == 0) {
                alert("Please select at least 1 main 'Career Journey' topics.");
            }
        });
    }

    countMaxStatements(newStatements) {
        let currentStatementCount = newStatements.length;

        let count = 0;
        let stop = false;
        this.maxStatementCountList.map((x, i) => {
            if (! stop && currentStatementCount < x) {
                count = x;
                stop = true;
            } 
        });   

        this.setState({ currentStatementMaxCount: count });
    }

    
    update() {
        setTimeout(() => {
            const Lookup = document.querySelectorAll(".lookup.active");
            const Remove = document.querySelectorAll(".lookup.remove");
            const StatementElms = document.querySelectorAll(".statement");
            const RoleElms = document.querySelectorAll(".role");
            const GoalElms = document.querySelectorAll(".goal");
            const SearchElms = document.querySelector(".search-it");

            this.setState({ statements: [] });
            let statementList = [];

            Lookup.forEach(elm => {
                console.log(elm.textContent);
                elm.text = elm.textContent.replace(/(([^\s]+\s+){10}).+/, "$1");
                if (elm.textContent == '') return;

                let text = elm.textContent;
                this.noList.forEach(nope => {
                    text = text.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g,' ').trim();
                });
                statementList.push(text);
            });

            StatementElms.forEach(elm => {
                elm.value = elm.value.replace(/(([^\s]+\s+){10}).+/, "$1");
                if (elm.value == '') return;

                let text = elm.value;
                this.noList.forEach(nope => {
                    text = text.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g,' ').trim();
                });
                statementList.push(text);
            });

            RoleElms.forEach(elm => {
                elm.value = elm.value.replace(/(([^\s]+\s+){10}).+/, "$1");
                if (elm.value == '') return;

                let text = elm.value;
                this.noList.forEach(nope => {
                    text = text.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g,' ').trim();
                });
                statementList.push(text);
            });

            GoalElms.forEach(elm => {
                elm.value = elm.value.replace(/(([^\s]+\s+){10}).+/, "$1");
                if (elm.value == '') return;

                let text = elm.value;
                this.noList.forEach(nope => {
                    text = text.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g,' ').trim();
                });
                statementList.push(text);
            });

            this.setState({ statements: statementList });
        }, 50);
    }




    render() {
        if (this.props.updateUserSearchFunction){   
            this.props.finishedUpdatingSearch();
            this.fetchStatmentsHandler();
        }

        if (this.props.active && ! this.state.active) {
            this.setState({ display: 'block', active: true, showMessage: true, messageDisplayCss: 'none' });
            
            setTimeout(() => {
                this.setState({ showMessage: false, messageDisplayCss: '' });  
            }, 7000);
        }

        if (!this.props.active && this.state.active) {
            this.setState({ display: 'none', active: false });
        }

        let color = "#333333";
        let backgroundColor = '#f4f4f4';
        let backgroundProgress = "#99a1a5";
        let inputBoxBackgroundColor = "#fff";
        let boxTagBackgroundColor = "#e9e9e9";
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            color = "#fff";
            backgroundColor = '#141f25';
            backgroundProgress = "#2e3b41";
            inputBoxBackgroundColor = "#192a30";
            boxTagBackgroundColor = "#d2d4d8";
        }
    
        let body = {
            display: this.state.display,
            height: this.props.windowHeight,
            width: this.props.windowWidth,
            backgroundColor: backgroundColor,
            padding: 20,
            position: 'fixed',
            zIndex: 80,
            top: 0,
            overflowY: 'auto'
        }

        let header = {
            display: this.state.display,
            height: 100,
            left: 0,
            width: this.props.windowWidth,
            backgroundColor: backgroundColor,
            padding: 20,
            position: 'fixed',
            zIndex: 81,
            top: 0
        }
    
        const closeStyle = {
            color: backgroundProgress,
            fontSize: 60,
            position: 'absolute',
            left: 20,
            top: 10
        }

        const clearSearchStyle = {
            color: '#7e878b',
            fontSize: 40,
            right: 40,
            top: 0,
            position: 'absolute',
            display: this.state.messageDisplayCss
        }
    
        const default_progress_bar_whole = {
            width: 'calc(70% - 20px)',
            height: 15,
            position: 'absolute',
            top: 58,
            left: 75,
            borderRadius: 13,
            backgroundColor: backgroundProgress
        }

        const rightArrow = {
            color: backgroundProgress,
            fontSize: 60,
            position: 'absolute',
            right: 20,
            top: 10,
            height: 40,
            width: 20
        }
    
        console.log(this.state.percentageComplete * 100);
        const progress_bar = {
            width:  (this.state.percentageComplete * 100) + '%',
            height: 'inherit',
            borderRadius: 13,
            color: color,
            padding: -5,
            backgroundColor: this.state.backgroundColor
        }
    
    
        const row_style = {
            paddingBottom: 40,
            paddingTop: 40
        }

        const row_inside_style = {
            paddingBottom: 20,
            paddingTop: 10,
            paddingLeft: 0
        }

        const quoteSection = { 
            color: color, 
            fontSize: 22, 
            paddingTop: 120, 
            paddingBottom: 120  
        }
    
        const topic_header = {
            marginLeft: 5,
            color: color,
            textAlign: 'left',
            fontSize: 18
        }

        const headers = {
            marginLeft: 5,
            color: color,
            textAlign: 'left',
            fontSize: 14
        }
    
        const inputbox = {
            marginTop: '1.1rem!important',
            padding: 10,
            paddingLeft: 20,
            paddingBottom: 20,
            paddingRight: 40,
            borderRadius: 10,
            textAlign: 'left',
            height: 'auto',
            backgroundColor: inputBoxBackgroundColor,
            border: "1px solid " + inputBoxBackgroundColor,
            color: color
        }

        const SearchBox = {
            marginTop: '1.1rem!important',
            padding: 24,
            paddingLeft: 40,
            paddingRight: 40,
            borderRadius: 10,
            minWidth: this.props.windowWidth - 80,
            backgroundColor: inputBoxBackgroundColor,
            border: "1px solid " + inputBoxBackgroundColor,
            fontSize: 18,
            color: color
        }

        const box = {
            backgroundColor: 'transparent',
            height: 100,
            overflowX: 'auto',
            width: '100%'
        }

        const insideBox = {
            maxWidth: 500,
            display: 'inline-flex'
        }

        let boxTag = {
            padding: 10,
            paddingTop: 6,
            paddingBottom: 6,
            borderRadius: 12,
            color: boxTagBackgroundColor,
            backgroundColor: "#",
            margin: 5,
            width: 'auto',
            minWidth: 'fit-content',
            width: 'fit-content',
            maxHeight: 50,
            fontSize: 14,
            zIndex: 2
        }

        let skip_style = {
            position: 'absolute',
            display: '',
            color: color,
            fontSize: 20,
            right: 20,
            top: 20
        }

        let input_section_style = {
            display: '',
            paddingLeft: 20, 
            paddingRight: 20
        }

        let localStatements = this.state.lookupActiveData.map(x => { return  x.statement.toLowerCase() });

        // This gives the user a little data at a time on onboard process.
        let count = Object.keys(this.state.lookupData).length;
        if (localStatements.length == 1) {
            count = 2;
            input_section_style.display = "none";
            skip_style.display = "none";
        }

        if (localStatements.length < 6) {
            skip_style.display = "none";
        }

        if (localStatements.length == 6) {
            skip_style.display = 'none';
        }

        if (localStatements.length > 6) {
            skip_style.display = 'none';
        }

        if (localStatements.length > 6) {
            count = Object.keys(this.state.lookupData).length; 
            input_section_style.display = "";
        }

        return (<div className='on-board' style={body}>
            <div id =".user-on-board" className="user-on-board">
                <div style={header}>
                    <div style={skip_style} onClick={this.exit}>Skip</div>
                    <div style={closeStyle} onClick={this.exit}>&times;</div>
                    <div style={default_progress_bar_whole}>
                        <div style={progress_bar}>
                        {/* {this.state.statements.length} / {this.state.currentStatementMaxCount} */}
                        </div>
                    </div>
                    {localStatements.length > 3 && <div onClick={this.exit}>
                        <span style = {{ position: 'absolute', right:40, color: "#678593" }}>Start</span>
                        <img style={rightArrow} src='./arrows/video_arrow.png' />
                    </div>}
                </div>

                <div id = "left-top-section" className='col-md left-top-section' style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div style={{ marginTop: 80}}>
                        {this.state.showMessage && <div style={quoteSection}>
                            <div className="on-board-message on-message">
                                <div>{this.state.messageDisplay}</div>
                                <small>choose what best fits you</small>
                            </div>
                        </div> }
                        <div className="row searchBox" style={{ display: 'inline-flex' }}>
                            <div><input type='text' className='form-control mt-2 search' style={SearchBox} placeholder='Search an Idea . . .' onKeyUp={this.SearchList} /></div>
                            <div style={clearSearchStyle} onClick={this.ClearSearch}>&times;</div>
                        </div>
                        {Object.keys(this.state.lookupData).map((mainKey, j) => {
                            if (j > 1) return;
                            let topic = mainKey.replace("_", " ");
                            topic = topic.toUpperCase();
                            // let localStatements = this.state.lookupActiveData.map(x => { return x.statement.toLowerCase() });
                            let activeData = localStatements.includes(mainKey.toLowerCase());
                            //console.log(localStatements)

                            return (j == 0 || j== 1 || activeData || mainKey.toLowerCase() == 'who am i') && <div style={row_style} className="lookup-section-topic">
                                <h4 style={topic_header}>{topic}</h4>        
                                    {Object.keys(this.state.lookupData[mainKey]).slice(0, count).map((key, index) => {
                                        let heading = key.replace("_", " ");
                                        heading = heading.charAt(0).toUpperCase() + heading.slice(1);
                                        
                                        return <div style={row_inside_style} className="lookup-section">
                                            <h6 style={headers}>{heading}</h6>
                                            <div style={box}>
                                                <div style={insideBox}>
                                                    {this.state.lookupData[mainKey][key].slice(0, this.state.lookupData[mainKey][key].length / 2 + 1).map(x => {
                                                        let classSet = "lookup";
                                                        let boxTagNow = JSON.parse(JSON.stringify(boxTag));
                                                        let id = '';  //#dfe0e1

                                                        boxTagNow.backgroundColor = "#dfe0e1";
                                                        boxTagNow.color = "#333333"; 
                                                        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                                                            boxTagNow.backgroundColor = "#1b272e";
                                                            boxTagNow.color = "#d2d4d8"; 
                                                        }
                                                        let add = <span></span>;
                                                        let ids = this.state.lookupActiveData.map(x => { return x.id});
                                                        if (localStatements.includes(x.toLowerCase())) {
                                                            let idx = localStatements.indexOf(x.toLowerCase());
                                                            console.log('index', idx);
                                                            boxTagNow.backgroundColor = "#0f73ad";
                                                            boxTagNow.color = "#fff";
                                                            
                                                            classSet = "lookup active hasid";
                                                            id = ids[idx];     
                                                        }
                                                        else 
                                                        {
                                                            add = <span  style={{ color: "#0f73ad", fontSize: 16, pointerEvents: 'none' }}> +</span>
                                                        }

                                                        return <div style={boxTagNow} className={classSet} data-id={id} onClick={this.makeBoxActive} heading={heading}>
                                                            <span className="child-item" style={{ pointerEvents: 'none' }}>{x}</span>
                                                            {add}
                                                        </div>
                                                    })}
                                                </div>
                                                <div style={insideBox}>
                                                    {this.state.lookupData[mainKey][key].slice(this.state.lookupData[mainKey][key].length / 2 + 1).map(x => {                             
                                                        let classSet = "lookup";
                                                        let boxTagNow = JSON.parse(JSON.stringify(boxTag));
                                                        let id = '';
                                                        boxTagNow.backgroundColor = "#dfe0e1";
                                                        boxTagNow.color = "#333333"; 
                                                        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                                                            boxTagNow.backgroundColor = "#1b272e";
                                                            boxTagNow.color = "#d2d4d8"; 
                                                        }
                                                        let add = <span></span>;
                                                        let ids = this.state.lookupActiveData.map(x => { return x.id});
                                                        if (localStatements.includes(x.toLowerCase())) {
                                                            let idx = localStatements.indexOf(x.toLowerCase());
                                                            console.log('index', idx);
                                                            boxTagNow.backgroundColor = "#0f73ad";
                                                            boxTagNow.color = "#fff";
                                                            classSet = "lookup active hasid";
                                                            id = ids[idx];
                                                        }
                                                        else 
                                                        {
                                                            add = <span style={{ color: "#0f73ad", fontSize: 16, pointerEvents: 'none' }}> +</span>
                                                        }

                                                        return <div style={boxTagNow} className={classSet} data-id={id} onClick={this.makeBoxActive}>
                                                            <span className="child-item" style={{ pointerEvents: 'none' }}>{x}</span>
                                                            {add}
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                           
                        })}
                        <div className="text-section" style = {input_section_style}>
                            <div style={row_style}>
                                {this.state.nonTopicStatements.length > 0 && <h4 style={headers}>Your Progress, Tasks and Goals</h4>}
                                <div className="row">
                                    <div className='col-md'>
                                        {this.state.nonTopicStatements.map((x, i) => {                        
                                            let words = x.statement.trim().split(" ");
                                            if (x.statement.trim() != '' && x.statement != null && x.id != '' && words.length >= 3 && i < 30) {
                                                return <div className="row">
                                                    <div type='text' className='form-control mt-2 user-text-statement' 
                                                        style={inputbox} 
                                                        data-id={x.id}>
                                                        {x.statement}
                                                    </div>
                                                </div> }
                                        })}              
                                    </div>
                                </div>
                            </div>
                        </div>                   
                        {/* THis is where the old input text for user used to be just in case to add it back look at git old code 7/14/2022 */}
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default UserIdea;