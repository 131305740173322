import React, { useState, useEffect, Component } from "react";

class Popup extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

        this.goButton = this.goButton.bind(this);
    }

    goButton() {
        console.log(this.props.componentType);

        if (this.props.componentType == 'recommend') {
            this.props.TurnOndetailsPageActive('recommend');
        }

        if (this.props.componentType == 'useridea') {
            this.props.TurnOndetailsPageActive('useridea');
        }

        this.props.runPopup();
        this.props.getRecommendationCallback(this.props.item, this.props.row, this.props.index);
    }


    render() {

        let left = 20;
        if (this.props.count == 3) {
            left = -20;
        }

        if (this.props.count == 2) {
            left = -90;
        }

        if (this.props.count == 1) {
            left = -50;
        }

        if (this.props.count == 3 && this.props.index == 0) {
            left = 0;
        }

        if (this.props.count == 3 && this.props.index == 1) {
            left = -80;
        }

        if (this.props.count == 3 && this.props.index == 2) {
            left = -130;
        }

        console.log(this.props.count);

        const popupStyle = {
            height: 'auto',
            width: this.props.windowWidth - 130,
            borderRadius: 12,
            padding: 13,
            backgroundColor: '#141d22f5', //'#20252af5', // '#3f4850f5',
            margin: 'auto auto',
            marginTop: 5,
            marginLeft: left,
            zIndex: 1,
            position: 'relative',
            padding: 10
        }

        const buttonStyle = {
            backgroundColor: '#fff',
            border: '1px solid transparent',
            borderRadius: 25,
            bottom: 20,
            width: 'calc(100% - 20px)',
            left: 10,
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 20,
            color: "black"
        }

        const desc = this.props.item.description.split(' ');

        let titleDesc = desc.splice(-15).join(' ');
        titleDesc = titleDesc.charAt(0).toUpperCase() + titleDesc.slice(1) + ".";

        titleDesc = titleDesc.split(' ').slice(7, ).join(' ');

        let description = this.props.item.description;
        description = description.charAt(0).toUpperCase() + description.slice(1);

        if (this.props.item?.title != null && this.props.item?.title != "") {
            titleDesc = this.props.item?.title;
            titleDesc = titleDesc.charAt(0).toUpperCase() + titleDesc.slice(1);
        }

        let model = "";
        if (this.props.item?.model != null && this.props.item?.tmodelitle != "") {
            model = this.props.item?.model;
            model = " / " + model.charAt(0).toUpperCase() + model.slice(1);
        }

        let dir = './arrows/';
        let img = '';
        let styleSet = { marginTop: 10, width: 70, height: 100 };
        if (this.props.item.opportunityCost > 0.15) {
            img = dir + 'right.png';
            styleSet.height = 70;
            styleSet.width = 50;
        }

        if (this.props.item.opportunityCost <= 0.15 && this.props.item.opportunityCost > 0) {
            img = dir + 'top-right.png';
            styleSet.height = 70;
            styleSet.width = 50;
        }

        if (this.props.item.opportunityCost < 0 && this.props.item.opportunityCost > -0.1) {
            img = dir + 'divert-left-dark.png';
            styleSet.height = 70;
            styleSet.width = 50;
        }

        if (this.props.item.opportunityCost < -0.10) {
            img = dir + 'left.png';
            styleSet.height = 70;
            styleSet.width = 50;
        }

        if (this.props.item.commit !== undefined && this.props.item.commit.Committed) {
            img = dir + 'forward.png';
            styleSet.height = 70;
            styleSet.width = 50;
        }


        return (<div style={popupStyle} onClick={this.goButton}>
            <div style= {{ display: 'inline-flex' }}>
                {img != '' && <div>
                    <img src={img} style={styleSet}/>
                </div>}
                <div>
                    {titleDesc != "" && 
                    <div style={{ padding: 10, marginBottom: 10 }}>
                        <div style={{ fontWeight: 'bold', color: '#fff', fontSize: 22, marginTop: 15 }}>{this.props.item.user_snippet} {model}</div>
                    </div>}
                </div>
            </div>
            {titleDesc != "" && 
                <div style={{ padding: 10, marginBottom: 10 }}>
                    {titleDesc}
                </div>}
            <button style={buttonStyle}>{this.props.titleMessage}</button>
        </div>
        );
    }
}

export default Popup;