import React, { useState, useEffect } from 'react'

class User extends React.Component {
    constructor(props) {
        super(props);
        this.User = {
            uid: 0,
            photoURL:'',
            phoneNumber: '',
            emailVerified: '',
            email: '',
            displayName: '',
            role: 0,
            roles: {
                base: 0,
                admin: 9,
                premium: 1
            }
        };

        this.user_premium_list = [
            // 'CTdZod8VWEco1CeWEU0BcLAAYLp2',
            // '3fW1SqTmI3SJFJu2R8bHkDnkGrU2',
            // 'Jz7WqtaafTatslAyAGGtS1FQCSo1'
        ];
        this.user_admin_list = [
            // 'WuubNXTPrndnHFko9ZcQKxZPo312'
        ]
    }

    setUser() {
        let User = JSON.parse(localStorage['user']);
        if (localStorage['userFromData'] != undefined && localStorage['userFromData'] != "undefined") {
            console.log(localStorage['userFromData']);
            const u = JSON.parse(localStorage['userFromData']);
            console.log(u);
            User.role = u.role;
            //localStorage['user'] = User;
        }

        this.User.uid = User.uid;
        this.User.photoURL = User.photoURL;
        this.User.phoneNumber = User.phoneNumber;
        this.User.emailVerified = User.emailVerified;
        this.User.email = User.email;
        this.User.displayName = User.displayName;
        this.User.roles = {
            base: 0,
            admin: 9,
            premium: 1
        }

        this.User.role = User.role ?? this.User.roles.base;
        //this.User.role = Object.values(this.User.roles).includes(User.role) ? User.role: this.User.roles.base;
        
        if (this.user_premium_list.includes(User.uid)) {
            this.User.role = this.User.roles.premium;
        }

        if (this.user_admin_list.includes(User.uid)) {
            this.User.role = this.User.roles.admin;
        }

        const u = JSON.parse(JSON.stringify(this.User));
        return u;
    }

    getUser() {
        return this.User;
    }
}

export default User;