import React, { useState, useEffect, Component } from "react";
import Popup from "./Popup";

class CircleTotal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayBox: false,
            displayBoxStyle: 'none'
        }

        this.popupButton = this.popupButton.bind(this);
        this.removePopup = this.removePopup.bind(this);
    }

    
    popupButton(e) {
        if(!this.state.displayBox) {
            this.setState({ displayBox: true, displayBoxStyle: '' });
        }
    }

    removePopup(e) {
        this.setState({ displayBox: false, displayBoxStyle: 'none' });
    }

    render() {
        const mainCircle = {
            width: 60,
            height: 60,
            position: 'fixed', 
            top: this.props.index * 100 + 150, 
            right: 10,
            zIndex: 5
        }

        let statusColor = '7px solid #3f4850';
        switch(this.props.status) {
            case 'completed':
                statusColor = '2px solid #3d663c';
                break;
            case 'unfinished':
                statusColor = '2px solid #ff801e';
                break;
            case 'default':
                statusColor = '2px solid #3f4850';
                break;
            default:
                statusColor = '2px solid #3f4850'; 
                break;
        }


        const outerCircle ={
            width: 'inherit',
            height: 'inherit',
            borderRadius: '50%',
            border: statusColor,
            zIndex: 5
        }

        const innerCircle = {
            width: 'inherit',
            height: 'inherit',
            borderRadius: '50%',
            border: '2px solid red',
            zIndex: 5
        }

        const titleStyle = {
            color: '#fff',
            marginTop: 5,
            fontWeight: 'normal',
            fontSize: 12,
            zIndex: -1, 
            backgroundColor: "#d5681e",
            borderRadius: 13,
            padding: 3,
            marginTop: -8
        }


        const desc = this.props.item.description.split(' ');

        let titleDesc = desc.splice(-15).join(' ');
        titleDesc = titleDesc.charAt(0).toUpperCase() + titleDesc.slice(1) + ".";

        let description = this.props.item.description;
        description = description.charAt(0).toUpperCase() + description.slice(1);

        let list = [
            'Get Started',
            "Next Up",
            "What's Next",
            "Starting This",
            "You're Right",
            "Being This",
            "Why Now",
            "What to Do"
        ]


        let title = this.props.item.title;

        let imgComponent = false;
        if (this.props.item.img!= undefined && this.props.item.img != '' && this.props.item.img != null ) {
            imgComponent = true;
        }

        const rotation = 2 * 28 * 3.1415 -  Math.round(2 * 28 * 3.1415 * this.props.item.completed);
      
        
        return (<div id={this.props.index} row={this.props.item} key={this.props.index} onClick={this.popupButton} onMouseLeave={this.removePopup} style={mainCircle}>
            <div className="outer-circle" style={outerCircle}>
                <svg xmins="http://www.w3.org/2000/svg" version="1.1" width="60px" height="60px" style={{ zIndex: 50 }}>
                    <defs>
                        <linearGradient id="gradient" className="inner-circle inner-circle-milestone" style={innerCircle}> 
                            <stop offset="1000%" stopColor = "#ff801e" />
                            <stop offset="0%" stopColor="#ff801e" />
                        </linearGradient>
                    </defs>
                    <circle cx={27.5} cy={28.5} r={28} strokeLinecap="round" style={{ strokeDashoffset: rotation }} />
                </svg>
                {imgComponent && <img style={{ height: 40,  width: 40, borderRadius: '50%', marginTop: -90, backgroundColor: 'transparent' }} src={this.props.item.img} />}
            </div>

            <div style={titleStyle}>{title}</div>
            <div className="snippet-section" style={{ display: this.state.displayBoxStyle, zIndex: 50 }}>
                {/* <Popup 
                    TurnOndetailsPageActive={this.props.TurnOndetailsPageActive} 
                    getRecommendationCallback={this.props.getRecommendationCallback} 
                    item = {this.props.item} 
                    count = {this.props.count} 
                    index = {this.props.index} 
                    windowWidth = {this.props.windowWidth} 
                    componentType = {this.props.componentType}
                    titleMessage = {this.props.titleMessage}
                /> */}
            </div>
        </div>
        );
    }
}

export default CircleTotal;