import React, { useState, useEffect, Component } from "react";
import DetailsPageIdea from "./DetailsPageIdea";

class DetailsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: "none",
            active: false,
            invokeAction: false,
        }

        this.exit = this.exit.bind(this);
        this.turnOn = this.turnOn.bind(this);
        this.turnOff = this.turnOff.bind(this);
    }

    exit() {
        this.setState({ display: 'none', active: false });
        this.props.TurnOffdetailsPageActive();
    }

    turnOn() {
        this.setState({ display: 'block', active: true, invokeAction: true });
        this.props.TurnOndetailsPageActive('recommend');
        setTimeout(() => {
            this.setState({ invokeAction: false, display: 'block', active: true });
        }, 300);
    }

    turnOff() {
        this.setState({ display: 'none', active: false, invokeAction: true });
        this.props.TurnOffdetailsPageActive();
        setTimeout(() => {
            this.setState({ invokeAction: false, display: 'none', active: false });
        }, 300);
    }


    render() {
        const {innerWidth, innerHeight} = window;

        if (this.props.active && ! this.state.active) {
            this.setState({ display: 'block', active: true, invokeAction: false });
            const body = document.getElementsByTagName('body');
            body.style="background-color:black";
        }

        let color = "#333333";
        let backgroundColor = '#fff';
        let backgroundProgress = "#99a1a5";
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            color = "#d2d4d8";
            backgroundColor = '#1c282e';
            backgroundProgress = "#2e3b41";
        }

        let body = {
            display: this.state.display,
            height: 60, //innerHeight - 50,
            width: innerWidth,
            color: color,
            backgroundColor: backgroundColor, //'#141f25',
            padding: 20,
            marginTop: 10,
            position: 'fixed',
            borderRadius: '40px 40px 0px 0px',
            zIndex: 80,
            top: 50,
            // overflowY: 'auto'
        }

        let bodyLower = {
            display: this.state.display,
            height: innerHeight - 120,
            width: innerWidth,
            backgroundColor: backgroundColor, //'#141f25',
            padding: 20,
            marginTop: 10,
            position: 'fixed',
            zIndex: 84,
            top: 110,
            overflowY: 'auto'
        }

        const closeStyle = {
            color: backgroundProgress,
            fontSize: 60,
            position: 'absolute',
            left: 20,
            top: -10
        }

        const default_progress_bar_whole = {
            width: 'calc(70% - 20px)',
            height: 15,
            position: 'absolute',
            top: 33,
            left: 75,
            borderRadius: 13,
            backgroundColor: backgroundProgress
        }

        let opportunityCost = 0;
        let display = 0;
        if (this.props.recommendation.opportunityCost != null && this.props.recommendation.opportunityCost != "" && this.props.recommendation.opportunityCost != 0) {
            opportunityCost = (this.props.recommendation.opportunityCost).toFixed(2);
            display = Math.abs(opportunityCost); //opportunityCost1.5 * Math.log10(Math.log10(Math.abs(opportunityCost * 100)));
         
            // if (opportunityCost < 0) {
            //     display = opportunityCost; //0.25 * Math.log10(Math.log10(Math.abs(opportunityCost * 100)));
            //     opportunityCost = display;
            // }  
            // else 
            // {
            //     opportunityCost = display;
            // }            
        }

        let backgroundColorBar = "#ff801e";
        if (this.props.recommendation.opportunityCost > 0) {
            backgroundColorBar = '#51a34f';
        }

        let progress_bar = {
            width: display * 100 + '%',
            height: 'inherit',
            borderRadius: 13,
            backgroundColor: backgroundColorBar,
            color: "#fff",
        }

        const detailsSection = {
            marginTop: 100,
            color: "#fff",
            textAlign: "left"
        }

        let term = 'ROI';
        if(opportunityCost < 0) {
            term = 'RISK';
        }

        return (<div className='bruh' >
            <div style={body}>
                <div style={{ display: 'inline-flex' }}>
                    <div style={closeStyle} onClick={this.exit}>&times;</div>
                    <div style={default_progress_bar_whole}>
                        <div style={progress_bar}>
                            <span style={{ marginTop: -2, position: 'absolute', left: '40%', fontSize: 12 }}>{(Math.abs(opportunityCost) * 100).toFixed(0) }% {term}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style = {bodyLower}>
                <DetailsPageIdea
                    recommendation = {this.props.recommendation}
                    index = {this.props.index}
                    row = {this.props.row}
                    openVideo = {this.props.openVideo}
                    TurnOndetailsPageActive = {this.props.TurnOndetailsPageActive}
                    TurnOffdetailsPageActive = {this.props.TurnOffdetailsPageActive}
                    fetchAIResponseHandler = {this.props.fetchAIResponseHandler}
                    getRecommendationCallback = {this.props.getRecommendationCallback}
                    turnOn = {this.turnOn}
                    turnOff = {this.turnOff}
                />
            </div>
        </div>
        );
    }
}

export default DetailsPage;