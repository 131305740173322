import React, { useState, useEffect, Component } from "react";
import User from "../User";
import axios from 'axios'
import packageJson from '../../../package.json';
import JSConfetti from 'js-confetti'

const api = axios.create({ "baseURL": packageJson.proxy });

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statements: [],
            values: [],
            loading: false,
            visible: true,
            Toggle: 'CREATE',
            title: 'CREATING',
            user: {},
            recommendation: {},
            recommendationBackground: {
                backgroundColor: ''
            },
            allVisible: false,
            placeholder: '',
            backgroundColor: '#ff801e',
            processingText: '',
            goButton: "Mint", 
            marginLeft: -45,
            minutes: 2,
            showMainButton: true,
            showPushButton: false,
            textBoxRightPosition: 40,
            zIndex: -1
        }
      
        this.nextHandler = this.nextHandler.bind(this);
        this.execute = this.execute.bind(this);
        this.confetti = this.confetti.bind(this);
        this.getUser = this.getUser.bind(this);
        this.typing = this.typing.bind(this);
        this.userUpdateAPI = this.userUpdateAPI.bind(this);
        this.pushUserUpdate = this.pushUserUpdate.bind(this);
        this.openDiscussionSection = this.openDiscussionSection.bind(this);
        this.closeDiscussionSection = this.closeDiscussionSection.bind(this);
        this.closeDiscussionSectionBase = this.closeDiscussionSectionBase.bind(this);
        this.getSelectedTopicsAndGoals = this.getSelectedTopicsAndGoals.bind(this);
        this.noList = [ ".", ",", "?", ";", ":", "'", '"', "<", ">", "=", "-", "(", "*", "&", "^", "$", "#", "@", "!", "~", "`", "+", "_" ];
   
   
        this.placeHolderList = [
            "working on my MVP and need a better functionality",
            "Started college this week and need help",
            "Need to get coding portfolio to look the best",
            "I have a wife and kids and building my startup",
            "How do I prepare for an investor meeting this week",
            "Need help getting this paper done on climate change",
            "Need a process to actually start advertising",
            "Looking at stocks and investments this week"
        ];
    }

    componentDidMount() {
        this.getUser();
        this.props.mintIt(this.nextHandler);
        if (localStorage['jobrunning_date'] != null) {
            const d = new Date();

            if (parseInt(localStorage['jobrunning_date']) < d.getTime()) {
                delete localStorage['jobrunning_date'];
                this.setState({ loading: false });
            }
            else 
            {
                let remainingTime = parseInt(localStorage['jobrunning_date']) - d.getTime()
                this.setState({ loading: true });
                const interval = setInterval(() => {
                    const d = new Date();

                    if (parseInt(localStorage['jobrunning_date']) < d.getTime()) {
                        delete localStorage['jobrunning_date'];
                        this.setState({ loading: false });
                        clearInterval(interval);
                        this.props.fetchAIResponseHandler();
                    }
                }, 1000 * 15);
            }
        }

        setTimeout(() => {
            this.setState({ statements: this.props.statements });
        }, 1000)
        
        setTimeout(() => {
            const statements = this.props.sendStatements()
            this.setState({ statements: statements });
        }, 6000);

        setTimeout(() => {
            let [ topics, goals ] = this.getSelectedTopicsAndGoals();
            console.log(goals);

        }, 10000);

        setTimeout(() => {
            console.log(this.state.recommendation);
            if (this.state.recommendation == null) {
                this.nextHandler();
            }
        }, 13000);
    }

    
    execute(e) {
        if (e.which == 13 || e.key == 13) {
            this.nextHandler();
        }
    }

    getUser() {
        const us = new User();
        const /** @User */ userSet = us.setUser();
        this.setState({ user: userSet });
    }

    openDiscussionSection(e) {
        if (!this.state.allVisible) {        
            let placeholder = this.placeHolderList[Math.floor(Math.random() * this.placeHolderList.length)];
            this.setState({ allVisible: true, placeholder: placeholder, showMainButton: false, zIndex: 82 });
            const q = document.querySelector(".updateuser");
            q.focus();
        }
    }

    closeDiscussionSection(e) {
        const q = document.querySelector('.footer-container');
        if (!q.contains(e.target)){
            this.setState({ allVisible: false, showMainButton: true, zIndex: -1 });
        }
    }

    closeDiscussionSectionBase() {
        this.setState({ allVisible: false, showMainButton: true, zIndex: -1 });
    }

    confetti() {
        const bubbles = document.querySelectorAll(".idea-bubble");
        if (this.state.statements.length == 0 || bubbles.length == 0) {

            const jsConfetti = new JSConfetti();
            jsConfetti.addConfetti({
                confettiColors: [
                  '#ff801e', '#3d663c', '#409d3d', '#3f4850', '#e6e933', '#fd5034', '#178cdf'  
                ],
            });
        }
    }

    getSelectedTopicsAndGoals() {
        const userstatements = document.querySelectorAll('.user-text-statement')
        const TopFocus = userstatements[0].textContent;
        const elements = document.querySelectorAll(".lookup-section-topic");

        let topics = [];
        let Goals = [];
        Goals.push(TopFocus);
        elements.forEach((elm, i) => {
            const elms = elm.querySelectorAll(".lookup.active")
            elms.forEach((row, j) => {
                if (i == 0) {
                    topics.push(row.textContent);
                    if (row.textContent.toLowerCase() == "startup") {
                        topics.push('business');
                    }
                }

                if (i == 1) {
                    let heading = row.getAttribute('heading');
                    Goals.push(heading + " " + row.textContent);
                }
            });
        });

        Goals.push(TopFocus);

        return [topics, Goals];
    }

    invokeMintHandler() {

    }

    nextHandler() {
        let statements = this.props.sendStatements();
        this.setState({ statements: statements });

        if (statements.length == 0)  {
            this.props.TurnOndetailsPageActive('useridea');
            alert("😂 Hey, you should at least take me to out to dinner and tell me about yourself first.");
            return;
        }

        if (statements.length < 3)  {
            this.props.TurnOndetailsPageActive('useridea');
            alert("😂 Hey, so we need at least 1 'Career Journey' topics and at lesat 3 goals to get ideas that could help you.");
            return;
        }

        const d = new Date();
        if (parseInt(localStorage['jobrunning_date']) > d.getTime()) {
            alert("🤔 Wait a moment. I'm still thinking.");
            return;
        }

        
        let [topics, goals] = this.getSelectedTopicsAndGoals();

        if (topics.length == 0)
        {
            this.props.TurnOndetailsPageActive('useridea');
            alert('You need to select at least one "career journey" topic at the top.')
            return;
        }

        if (goals.length == 0)
        {
            this.props.TurnOndetailsPageActive('useridea');
            alert('You should select at least one "Goal" from the goal list');
            return;
        }

        let statementList = [];

        // allow for confetti to drop if brand new user or when the user uses it's for the first time that day.
        this.confetti();

        
        statements.forEach(phrase => {
            phrase = phrase.replace(/(([^\s]+\s+){10}).+/, "$1");
            if (phrase == '') return;

            let text = phrase;
            this.noList.forEach(nope => {
                text = text.replace(/[^a-zA-Z0-9 ]/g, '');
            });

            statementList.push(text);
        });

        this.props.TurnOffdetailsPageActive();

        setTimeout(() => {
            this.closeDiscussionSectionBase();
        }, 2000);
        

        this.setState({ Toggle: 'CREATE', title: "What's you next move?" });

        const userid = localStorage['userid'];
        let axiosConfig = {
          headers: {
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                "Host": "https://us-central1-cofounderapi.cloudfunctions.net/",
                "Origin": "https://cofounderaiai.com",
                "Referer": "https://us-central1-cofounderapi.cloudfunctions.net/"
            }
        };

        let currentStatements = statements.filter(x => { if (x != "" && x != " ") return x; })

        let thisState = JSON.parse(JSON.stringify(this.state.recommendationBackground));
        thisState.backgroundColor = '#e8edf1'
        this.setState({ loading: true, marginLeft: -35 });
        this.setState({ processingText: "processing about 2 min ..." });
 
        console.log(currentStatements);
       
        let month = d.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }

        let getDate = d.getDate();
        if (getDate < 10) {
            getDate = "0" + getDate;
        }

        let getHours = d.getHours();
        if (getHours < 10) {
            getHours = "0" + getHours;
        }

        let getMinutes = d.getMinutes();
        if (getMinutes < 10) {
            getMinutes = "0" + getMinutes;
        }

        let getSeconds = d.getSeconds();
        if (getSeconds < 10) {
            getSeconds = "0" + getSeconds;
        }


        const today = d.getFullYear() + "-" + month + "-" + getDate + " " + getHours + ":" + getMinutes + ":" + getSeconds;

        let url = "/next-ai-step-1?statements=" + currentStatements.join('|') + "&u=" + this.state.user.uid + "&timestamp=" + today + "&user_topics=" + this.props.topics.join("|") + "&goals=" + goals.join('|');

        localStorage['jobrunning_date'] = d.getTime() + 1000 * 60 * this.state.minutes;
        console.log(localStorage);

        setTimeout(() => {
            delete localStorage['jobrunning_date'];
        }, 1000 * 60 * this.state.minutes);

        const userMessageTitle = document.querySelector('.user-body-message-title');
        const userMessage = document.querySelector('.user-body-message');
        userMessageTitle.innerHTML = "Trying to help you out";
        userMessage.innerHTML = "Wait about <span style='color: #ff801e; font-size: 16px'>3 Minutes.</span> I am thinking about everything you said and trying to offer ideas.";
        
        api({
            method: 'get',
            url: url, // next-ai-step?statements=" + this.state.statements.join('|'),
            headers: axiosConfig,
            data: { statements: currentStatements.join('|'), user: this.state.user, timestamp: today, uset_topics: this.props.topics.join("|") }
        }).then(response => { 
            let thisState = JSON.parse(JSON.stringify(this.state.recommendationBackground));
            thisState.backgroundColor = 'unset'

            this.setState({ recommendation: response.data, 
                addnewResultdata: response.data, 
                recommendationBackground: thisState, 
                backgroundColor: "#339b1d",
                loading: false,
             });
           
            delete localStorage['jobrunning_date'];
            this.props.fetchAIResponseHandler();
            userMessageTitle.innerHTML = "Found a few ideas for you";
            userMessage.innerHTML = "Hopefully what i provide will help. If it doesn't then you can try again.";

            setTimeout(() => {
                this.setState({ backgroundColor: "#ff801e", goButton: 'Mint', marginLeft: -45 }); 
                //userMessage.innerHTML = "";
            }, 10000);
        })
        .catch(reason => {

            delete localStorage['jobrunning_date'];
            this.props.fetchAIResponseHandler();
            this.setState({ backgroundColor: "#f74d4d", loading: false, marginLeft: -35 });
            userMessageTitle.innerHTML = "I think things didn't go well";
            userMessage.innerHTML = "Hey so if you didn't see any updates from me in a few minutes, I suggest try minting again.";
            setTimeout(() => {
                this.setState({ backgroundColor: "#ff801e", goButton: 'Mint', marginLeft: -45 });                 
                //userMessage.innerHTML = "";
            }, 10000);
        });
    }

            
    userUpdateAPI(currentStatement, callback) {
        let axiosConfig = {
          headers: {
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                "Host": "https://us-central1-cofounderapi.cloudfunctions.net/",
                "Origin": "https://cofounderaiai.com",
                "Referer": "https://us-central1-cofounderapi.cloudfunctions.net/"
            }
        };

        this.getUser();

        api({
            method: 'post',
            url: "/edit-thought",
            headers: axiosConfig,
            data: { statements: JSON.stringify([{ id: '', statement: currentStatement }]), user: JSON.stringify(this.state.user) }
        }).then(response => { 
            this.props.updateUserSearch();
            if (typeof callback === "function") {
                callback();
            }
        });
    }

    pushUserUpdate() {
        const row = document.querySelector('.updateUser');
        let words = row.value.split(" ");
        if (words.length < 4) {
            row.style.color = "red";
            return;
        }

        row.style.backgroundColor = "#141f25";
        row.style.color = "#686b75";

        this.userUpdateAPI(row.value, () => {
            row.value = '';
            row.style.backgroundColor = "#202930";
            row.style.color = "#fff";
            this.setState({ showPushButton: false, textBoxRightPosition: 28 });
        });

        row.value = '';
    }

    typing(e) {
        if (e.target.value != "") {
            this.setState({ showPushButton: true, textBoxRightPosition: 30 });

            e.target.style.color = "#333333";
            if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                e.target.style.color = "#fff";
            }
            
            let words = e.target.value.split(" ");

            if (words.length > 10) {
                let newWords = [];
                for (let i = 0; i < 10; i++) {
                    newWords.push(words[i]);
                }

                e.target.value = newWords.join(" ");
            }

            if (e.which == 13 || e.keyCode == 13) {
                if (words.length < 4) {
                    e.target.style.color = "red";
                    return;
                }

                this.userUpdateAPI(e.target.value, () => {
                    e.target.value = '';
                    this.setState({ showPushButton: false, textBoxRightPosition: 30 });
                });  
            }
        }
        else 
        {
            this.setState({ showPushButton: false, textBoxRightPosition: 30 });
        }
    }


    render() {
        let display = "";
        if (!this.props.showFooter) {
            display = 'none';
        }

        let color = "#333333";
        let backgroundColor = "#f4f4f4";
        let backgroundTextBoxColor = "#fff";
        let borderTop = "#eff3f5";
        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            color = "#fff";
            borderTop = "#2e3b41";
            backgroundColor = "#202930";
            backgroundTextBoxColor = "#202930";
        }

        let borderTopFooter = '2px solid ' + borderTop;
        if (!this.state.allVisible) {
            borderTopFooter = 'unset';
            backgroundColor = "transparent";
        }

        const footStyle = {
            padding: 10,
            borderRadius: "15px 15px 0 0 ",
            backgroundColor: backgroundColor,      
            color: color,
            bottom: 0,
            width: '100%',
            height: 140,
            position: 'fixed',
            // borderTop: borderTopFooter,
            zIndex: this.state.zIndex,
            display: display
        }
    
        const runMintStyle = {
            position: 'absolute',
            bottom: 20,
            right: 20,
            marginLeft: this.state.marginLeft,
            backgroundColor: this.state.backgroundColor,
            border: this.state.backgroundColor,
            borderRadius: 20,
            fontWeight: 'bold',
            fontSize: 20,
            padding: 8,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 0,
            paddingBottom: 5
        }

        const runPlusStyle = {
            position: 'fixed',
            bottom: 20,
            right: 20,
            marginLeft: this.state.marginLeft,
            backgroundColor: this.state.backgroundColor,
            border: this.state.backgroundColor,
            borderRadius: '50%',
            fontWeight: 'bold',
            fontSize: 32,
            padding: 20,
            paddingTop: 5,
            paddingBottom: 10,
            zIndex: 82
        }

        const userUpdateStyle = {
            position: 'absolute',
            bottom: 10,
            right: 17,
            marginLeft: this.state.marginLeft,
            backgroundColor: this.state.backgroundColor,
            border: this.state.backgroundColor,
            borderRadius: 20,
            fontWeight: 'bold',
            fontSize: 20,
            padding: 8,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 0,
            paddingBottom: 5
        }

        const realTimeUpdates = {
            backgroundColor: backgroundTextBoxColor,
            padding: 10,
            top: 15,
            width: window.innerWidth - 30,
            border: '1px solid transparent', // + backgroundTextBoxColor,
            borderRadius: 15,
            left: 15,
            position: 'absolute',
            color: color
        };

        return (<div>
            {this.state.allVisible && <div className="footer-backlayer" onMouseDown={this.closeDiscussionSection} 
                style={{ 
                    position: 'fixed', 
                    left: 0, 
                    top: 0, 
                    width: "100%", 
                    height: "100%", 
                    backgroundColor: "#0e1214b5",
                    zIndex: 81
            }}></div>}
            <div className="footer-container" style={footStyle} >
                {! this.state.showPushButton && this.state.allVisible && <button style={runMintStyle} onClick={this.nextHandler} className="btn btn-success">
                    {!this.state.loading && this.state.goButton}
                    {this.state.loading && <div className="spinner-border" role="status" 
                        style={{ position: 'relative', textAlign: 'center', marginTop: 5 }}>
                    </div>}
                </button>}

                {this.state.allVisible && <input autoFocus className="updateUser" type="text" style={realTimeUpdates} onKeyUp={this.typing} placeholder={"e.g., " + this.state.placeholder}  />}
                {this.state.allVisible && this.state.showPushButton && <button style={userUpdateStyle} onClick={this.pushUserUpdate} className="btn btn-success">
                    <img style={{ height: 20, width: 15 }} src="./arrows/forward.png" />
                </button>}
            </div>

            {this.state.showMainButton && <button style={runPlusStyle} onClick={this.openDiscussionSection} className="btn btn-success">
                    +
            </button>}
        </div>
        );
    }
}

export default Footer;