import React, { useState, useEffect, Component } from "react";
import { UncontrolledAccordion } from "reactstrap";
import CircleTotal from "./CircleTotal";
import IdeaRow from './IdeaRow';
import OpportunityGroup from "./OpportunityGroup";
import opportunities from '../../opportunities.json'

class MainBody extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            statements: this.props.statements,
            status: 'default',
            recommendations: [],
            originalRecommendations : [],
            dates: [],
            loaded: false,
            date: this.props.date,
            isUpdateAllBubbles: false,
            showAllToday: false,
            activeTopic: 'All'
        }

        this.UpdateAllBubbles = this.UpdateAllBubbles.bind(this);
        this.autoAjustRecommendations = this.autoAjustRecommendations.bind(this);
        this.getSelectedTopicsAndGoals = this.getSelectedTopicsAndGoals.bind(this);
        this.showAllTodaysNodes = this.showAllTodaysNodes.bind(this);
        this.activateTopic = this.activateTopic.bind(this);
        this.defaultRow = {
            id: -2,
            title: "your startup if you're only working on it part time 'Part-Time Startups:'",
            opportunityCost: 0.5,
            description: "your company given that you're not working on it full timeThe probability of a startup being successful is low, and it is even lower if the founders are only working on it part time.",
            query: '',
            roi: 0.5,
            steps: [
                {
                    title: "5 Tip or Getting Your Business off The Ground",
                    step: "Build a project on Github",
                    roi: -3.006,
                    cost: 0
                },
                {
                    title: "1. Define your business model and value proposition",
                    step: "Build a project on Github",
                    roi: 2.5007,
                    cost: 0
                }
            ],
            people: [
                {
                    likelihood: 0.089,
                    name: "How to launch a Successful Side Hustle",
                },
                {
                    likelihood: 0.28,
                    name: "1. Tim Ferriss"
                },
                {
                    likelihood: 0.19,
                    name: "2. Casey Carden"
                }
            ],
            snippet: 'startup',
            runTime: 10,
            video: "https://www.youtube.com/embed/NyYu00uMmS0",
            user_snippet: 'startup',
            img: '',
            cost: 0,
            longText: "i think the question was if you're working part time on your company because you have to work full time how do you stack up well well i think i think the hard answer there is there's few startups that are successful. working part time so eventually if you're not full-time the likelihood that you're gonna  know even if you're amazing and you're working 40 hours a week in one job in 40 hours a week in. another your probability of success is pretty low can you guys think of any company any amazing company where part-time founders actually made it amazing so i think the question isn't really to us it's to you how successful can you make. your startup working part time i think you can get it going but at some point at some point in your startups in the in the evolution of your startup you're going to have to commit to it if you want to. make that startup real yes there is really no such thing the question was about like the advisor trac program there's really no such thing there's one program with all 15,000 companies and we're going to try to make the program as",
            videoIds:[],
            roleModelsLikelihood: [],
            peopleWhoWould: [],
            commit: {
                Commited: false
            }
        };
    }

    componentDidMount() {

    }

    getSelectedTopicsAndGoals() {
        const elements = document.querySelectorAll(".lookup-section-topic");

        let topics = [];
        let Goals = [];
        elements.forEach((elm, i) => {
            const elms = elm.querySelectorAll(".lookup.active")
            elms.forEach((row, j) => {
                if (i == 0) {
                    topics.push(row.textContent);
                }

                if (i == 1) {
                    Goals.push("my goal is " + row.textContent);
                }
            });
        });

        return [topics, Goals];
    }

    activateTopic(e) {
        if (!e.target.classList.contains('active')) {
            const userTopicListItems = document.querySelectorAll('.user-topic-list-item');
            userTopicListItems.forEach(elm => {
                elm.classList.remove('active');
                elm.style.color = "#848791";
                elm.style.borderBottom = "none";
            });

            e.target.classList.add('active');
            e.target.style.color = '#ff801e';
            e.target.style.borderBottom = "2px solid #ff801e";
            this.setState({ activeTopic: e.target.textContent })
        }
        else 
        {
            e.target.classList.remove('active');
            e.target.style.color = "#848791";
            e.target.style.borderBottom = "none";
        }
    }

    showAllTodaysNodes(e) {
        if (! e.target.classList.contains("active")) {
            e.target.classList.add('active');
            this.setState({ showAllToday: true });
        }
        else
        {
            e.target.classList.remove('active');
            this.setState({ showAllToday: false });
            const g = document.querySelector('.game-plan-body');
            g.scrollTop = 0;
        }
    }

    autoAjustRecommendations() {
        let recommendationList = this.props.recommendationList.options;
        let dates = this.props.recommendationList.data;

        recommendationList.push([this.defaultRow]);
        console.log("last date:", dates);
        if (dates.length > 0) {
            let last = dates[dates.length - 1];
            console.log("ab last date:", last);
            dates.push(last);
        }
        else 
        {
            dates.push({ date: Date.now() });
        }

        this.setState({ 
            recommendations: recommendationList, 
            originalRecommendations: this.props.recommendationList.options,
            dates: dates, 
            loaded: true 
        });
    }

    UpdateAllBubbles() {
        this.setState({ isUpdateAllBubbles: true });
    }

    render() {
        const {innerWidth, innerHeight} = window; 
        let status = "default";  
        if (this.props.statements.length > 4) {
            status = "completed";
        }
        else if (this.props.statements.length > 1) {
            status = "unfinished";
        }

        // if (this.props.recommendationList.options !== undefined && this.props.recommendationList.options != this.state.originalRecommendations) {
        //     this.autoAjustRecommendations();
        // }

        let backgroundColor = "#f4f4f4";
        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            backgroundColor = "#141f25";
        }

        const bodyNow = {
            padding: 10,
            paddingTop: 10,
            paddingBottom: 60,
            backgroundColor: backgroundColor,
            color: "#fff",
            height: innerHeight - 120,
            width: '100%',
            overflowY: 'auto',
            minHeight: innerHeight - 120,
            marginTop: 0,
            position: 'absolute',
            top: 120
        };

        const count = 1;
        const width = 120;
        const innerMargin = (innerWidth - (count * width)) / (count + 1)

        const milestoneCSS = {
            width: 100,
            height: 100,
            display: 'block',
            marginLeft: innerMargin,
            marginRight: innerMargin,
            marginBottom: 100
        }

        const milestoneImg = {
            width: 100,
            height: 100,
            display: 'block',
            position: 'absolute'
        }

        let activateTopicStyle = { 
            margin: 5, 
            marginLeft: 10, 
            marginRight: 10, 
            paddingBottom: 15,
            color: "#848791" 
        };

        const user = JSON.parse(localStorage['user'])
        const profile = user != null ? user.photoURL: '';

        const milestoneMarkers = [ 4, 15, 25 ];
        const milestones = [
            {
                img: 'phone.png',
                description: '',
                title: 'Week',
                top: 300,
                completed: 0.39
            },{
                img: 'goal.png',
                description: '',
                title: 'End Goal',
                top: 500,
                completed: 0.01
            }
        ]

        let usedids = [];

        let [topics, goals] = this.getSelectedTopicsAndGoals();
        let originalTopics = [];
        originalTopics.push('All');
        topics.map(x => {
            originalTopics.push(x);
        });
        
        topics.push('Schedule')
        topics.push('Products');
        topics.push('Mentors')
        topics.push('Podcast');
        topics.push('Stocks');
        topics.push('Food')
        topics.push('Services');
        topics.push('Courses');
        topics.push('Bootcamps');
        topics.push('Technologies');

        let TopicHash = {};
        TopicHash['Career'] = ["Career", "Courses", 'Bootcamps'];
        TopicHash['Startup'] = ["Startup", "Stocks", "Marketing"];
        TopicHash['Marketing'] = ["Startup", "Marketing"];
        TopicHash['College'] = ["College"];
        TopicHash['Climate'] = [ "Climate" ];
        TopicHash['Investing'] = ["Investing", "Startup", "Stocks"]; 

        let color = "#333333";
        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            color = "#fff";
        }

        const header = { 
            textAlign: 'left', 
            marginLeft: 30, 
            marginTop: 20, 
            fontSize: 18, 
            color: color, 
            fontWeight: 'bold' 
        }

        let activeList = [];

        return (<div className='game-plan-body' style={bodyNow}>           
            {/* {milestones.map((item, index) => { 
                return <CircleTotal 
                    style = {{  }}
                    TurnOndetailsPageActive={this.props.TurnOndetailsPageActive} 
                    getRecommendationCallback={this.props.getRecommendationCallback}
                    row = {item}
                    key={'circle-milestone-' + index} 
                    count={1} 
                    windowWidth={innerWidth} 
                    innerMargin={innerMargin} 
                    index = {index} 
                    total = {1} 
                    item = {item}
                    componentType = {'useridea'} 
                    titleMessage = {'milestone'}
                    status = {'default'}
                />
            })} */}
            <div style={{ display: 'block', height: 155, marginBottom: 50 }}>
                <IdeaRow 
                    key={-1} 
                    row={-1} 
                    TurnOndetailsPageActive={this.props.TurnOndetailsPageActive} 
                    getRecommendationCallback={this.props.getRecommendationCallback} 
                    windowWidth = {innerWidth} 
                    items={[{ 
                        id: '29232134', 
                        title: '"' + "History NEVER Repeats itself, but it does Often Rhyme" + '"' + " - Mark Twain", 
                        user_snippet: 'My Vision',
                        img: profile, 
                        description: '',
                        opportunityCost: 0.01,
                        commit: { 
                            Commited: false
                        }
                    }]}
                    componentType = {"useridea"} 
                    titleMessage = {"Let's Go"}
                    status = {status}
                    date={this.props.date} // #13181cf5
                />
            </div>
            {/* <div style = {{ display: "inline-flex", overflowX: 'auto', width: innerWidth - 70, marginLeft: 20, marginRight: 20, textAlign: 'center', marginTop: 60 }}>
                {originalTopics.map((userTopic, i) => {
                    if (i == 0) {
                        let ats = JSON.parse(JSON.stringify(activateTopicStyle));
                        ats.color = '#ff801e';
                        ats.borderBottom = "2px solid #ff801e";
                        return <div style={ats} className="user-topic-list-item active" onClick={this.activateTopic}>{userTopic}</div>
                    }
                    else 
                    {
                        return <div style={activateTopicStyle} className="user-topic-list-item" onClick={this.activateTopic}>{userTopic}</div>
                    }
                })}
            </div> */}
            {/* {<div style={header}>
                Next Game Plan
            </div>} */}
            <div className="all-ideas-window">
                {this.props.recommendationList.options.map((items, index) => {    
                    let show = true; //index < 6 ? true: false;  //new Date(this.props.recommendationList.data[index].date).getTime() + 1000 * 60 * 60 * 24 * 3 > Date.now()? true: false;
                    let d = new Date();

                    if (!this.state.showAllToday && index > 1) {
                        return;
                    }

                    if (show) {
                        if (this.props.recommendationList.data != undefined && this.props.recommendationList.data[index] != undefined  && this.props.recommendationList.data[index].date != undefined) {
                            let currentDate = this.props.recommendationList.data[index].date;
                            if (currentDate instanceof String){
                                currentDate = currentDate.replace(" ", "T");
                            }
                            
                            d = new Date(currentDate.replace(" ", "T"));
                        }   

                        let month = d.getMonth() + 1;
                        if (month < 10) {
                            month = "0" + month;
                        }
                
                        let getDate = d.getDate();
                        if (getDate < 10) {
                            getDate = "0" + getDate;
                        }
                        const currentDate = d.getFullYear() + "-" + month + "-" + getDate;
                        console.log('final: ', currentDate, this.props.date);
                        if (currentDate == this.props.date) {
                            show = true;
                            activeList.push(items);
                        }
                        else 
                        {
                            show = false;
                        }

                        if (usedids.includes(items[0].title)) {
                            //show = false;
                        }

                        usedids.push(items[0].title);
                    }

                    return show && <div className="idea-bubble" style={{ display: 'block', height: 185 }}>
                        <IdeaRow  
                            row={index} 
                            key={index} 
                            TurnOndetailsPageActive={this.props.TurnOndetailsPageActive} 
                            getRecommendationCallback={this.props.getRecommendationCallback}
                            items={items} 
                            windowWidth = {innerWidth}
                            componentType = {"recommend"} 
                            titleMessage = {"Let's Go"}
                            status = {'default'}
                            date={d}
                            activeTopic = {this.state.activeTopic}
                            isUpdateAllBubbles = {this.state.isUpdateAllBubbles}
                            UpdateAllBubbles = {this.UpdateAllBubbles}
                        />
                    </div>
                })}
            </div>
            {this.props.recommendationList.options.length > 0 && <div style={{ fontSize: 50, textAlign: 'center' }} onClick={this.showAllTodaysNodes}>...</div>}
            {activeList.length == 0 && <img style={{ width: 100, height: 100 }} src="./mountain2.png" />}
            {activeList.length == 0 && <div style={{ color: color, padding: 15, paddingLeft: 50, paddingRight: 50 }}>
                <div className="user-body-message-title" style={{ fontSize: 16 }}>MINT new tasks and Ideas </div>
                <div className="user-body-message" style={{ fontSize: 13 }}>After minting choose ideas you want to improve your career Game Plan.</div>
            </div>}

            {/* <div style={header}>
                Opportunities (Testing)
            </div>
            {Object.keys(opportunities).map((key, i) => {
                if (topics.includes(key)) {
                    if (TopicHash[key] != undefined) {
                        let iTopics = TopicHash[key];
                        iTopics.map(ikey => {
                        //     let itemi = opportunities[ikey];
                        //     return itemi.title != "" && <OpportunityGroup 
                        //     item = {itemi}
                        // />
                        });
                    }
                    else 
                    {
                        const item = opportunities[key];
                        return item.title != "" && <OpportunityGroup 
                            item = {item}
                        />
                    }
                }
            })}           */}
        </div>
        );
    }
}

export default MainBody;