import React, { useState, useEffect, Component } from "react";
import ReactPlayer from "react-player";


class Video extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            display: 'none',
            active: false,
            playing: true
        }

        this.close = this.close.bind(this);
        this.showVideo = this.showVideo.bind(this);
    }
  

    close() {
        this.setState({ display: 'none', active: false, playing: false });
        this.props.closeVideo();
    }

    showVideo() {
        const item = document.getElementById("video-container-set");
        
        if (!item.classList.contains('active')) {
            item.classList.add('active');
            item.style.left = '50px';
        }
        else 
        {
            item.classList.remove("active");
            item.style.left = '-290px';
        }
    }

    render() {
        if (this.props.active && ! this.state.active) {  
            this.setState({ display: 'block', active: true, playing: true });
        }

        const viewStyle = {
            display: this.state.display,
            width: 250,
            height: 190,
            borderRadius: '20px 0 0 20px',
            position: 'fixed',
            left: 50,
            bottom: 80,
            zIndex: 90,
            overFlow: 'hidden',
            color: '#fff'
        }

        const closeStyle = {
            color: '#fff',
            fontSize: 20,
            position: 'absolute',
            right: -40,
            top: 5,
            backgroundColor: '#00000083',
            padding: 5,
            paddingTop: 2,
            paddingBottom: 3,
            borderRadius: '3px 0 0 3px',
            zIndex: 91
        }

        const videoStyle = {
            borderRadius: '10px 0 0 10px',
            cursor: "move",
            color: '#fff'
        }

        const viewInsideStyle = {
            cursor: "move"
        }

        let videoUrl = '';
        if (this.props.video != '') {
            videoUrl = this.props.video + '?autoplay=1';
        }
        else 
        {
        }

        function dragElement(elmnt) {
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            if (document.getElementById(elmnt.id + "header")) {
                /* if present, the header is where you move the DIV from:*/
                document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
            } 
            else 
            {
                /* otherwise, move the DIV from anywhere inside the DIV:*/
                elmnt.onmousedown = dragMouseDown;
            }
        
            function dragMouseDown(e) {
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                document.onmouseup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
            }
        
            function elementDrag(e) {
                e = e || window.event;
                e.preventDefault();
                // calculate the new cursor position:
                pos1 = pos3 - e.clientX;
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;
                // set the element's new position:
                elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
                elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
            }
        
            function closeDragElement() {
                /* stop moving when mouse button is released:*/
                document.onmouseup = null;
                document.onmousemove = null;
            }
        }

        //dragElement(document.getElementById("video-container-set"));

        return <div id="video-container-set" class="video-container active" style={viewStyle}>
            <div style={{ display: 'inline-flex' }}>
                <div id="video-container-setheader" style={viewInsideStyle}>
                    <div style={closeStyle} onClick={this.close}>&times;</div>
                    {/* <iframe id="video-main"
                        height ='170' width = '290' style={videoStyle} src={videoUrl} title="YouTube video player" frameborder="0" autoplay="1" allow="accelerometer; autoplay; rel; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe> */}
                    <ReactPlayer
                        url={videoUrl}
                        playing={this.state.playing}
                        controls={true}
                        width={290}
                        height={170}
                    />

                </div>
                <div style={{ 
                        height: 170, width: 20, backgroundColor: '#3f4850', 
                        borderRadius: '0 10px 10px 0', marginRight: -15, 
                        fontWeight: 'bold', verticalAlign: 'center', paddingTop: 50 
                    }} 
                    onClick={this.showVideo}>
                    <img style={{ marginLeft: -5}} src='./arrows/video_arrow.png' />
                </div>
            </div>
        </div>
    }
}

export default Video;
    