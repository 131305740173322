import UserBoard from './UserBoard';
import UserSearch from './UserSearch';
import UserHeader from './UserHeader';
import MainWrapper from './duo/MainWrapper';
import packageJson from '../../package.json';

function Main() {
    const user = JSON.parse(localStorage['user'])
    const uid = user != null ? user.uid: '';
    const userData = user != null ? user: {};

    const appEnv = packageJson.appEnv;
  
    if (user != null) {
        const sizeMobile = window.matchMedia("(max-width: 780px)").matches
        
        if (appEnv == "app") {
            if (sizeMobile) {
                return (<div>
                    <MainWrapper />
                </div>);
            }
            else 
            {
                return (<div style={{ backgroundColor: "#fff", fontSize: 40, height: 500, marginTop: 100, paddingTop: 200, width: '100%', textAlign: 'center', verticalAlign: 'middle' }}>
                    Hey, this app is on mobile only. Check us out on your phone.
                </div>);
            }
        }

        if (appEnv == "api") {
            if (sizeMobile) {
                return (<div style={{ backgroundColor: "#fff", fontSize: 40, height: 500, marginTop: 100, paddingTop: 200, width: '100%', textAlign: 'center', verticalAlign: 'middle' }}>
                    Hey, this API is for desktop right now.
                </div>);
            }
            else 
            {
                return (<div style={{ backgroundColor: "#fff" }}>
                    <UserHeader />
                    <UserBoard />
                </div>);
            }
        }
    }
    else 
    {
        return <div></div>;
    }
}

export default Main;