import React, { useState, useEffect, Component } from "react";
import DetectDarkMode from 'detect-dark-mode';
import User from "../User";
import logo from '../../logo-full.png';
import premium_logo from '../../premium-icon.png';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            candyMenu: true
        }
        this.user = this.getUser();
        this.reset = this.reset.bind(this);
        this.switch = this.switch.bind(this);
    }

    reset() {
        this.props.fetchAIResponseHandler();
    }

    getUser() {
        const us = new User();
        us.setUser();
        const /** @User */ userSet = us.getUser();
        this.setState({ user: userSet });
        return userSet;
    }

    switch() {
        this.props.switchDisplay();
        if (this.state.candyMenu) {
            this.setState({ candyMenu: false });
        }
        else 
        {         
            this.setState({ candyMenu: true });
        }
    }

    render() {
        let backgroundColor = "#f4f4f4";
        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            backgroundColor = "#141f25";
        }

        const style = {
            height: '50px',
            padding: 10,
            backgroundColor: backgroundColor,
            color: "#fff",
            position: 'fixed',
            // borderBottom: '2px solid #2e3b41',
            zIndex: 10,
            width: '100%' 
        }
    
        const logout_style = {
            position: 'absolute',
            right: 20,
            top: 8,
            backgroundColor:'#ff801e',
            border: '#ff801e'
        }
    
        const logo_style = {
            color: "#fff",
            fontSize: 15,
            position: 'absolute',
            left: "30px",
            top: 0,
            display: 'inline-flex'
        }
    
        const logo_img = {
            top: 5,
            height: '50px',
            left: 0
        }

        const role_style = {
            width: '35px',
            height: '35px',
            borderRadius: '50%',
            position: 'absolute',
            right: 140,
            top: 10
        }

        const menu_style = {
            width: '35px',
            height: '35px',
            position: 'absolute',
            right: 140,
            top: 10
        }

        const profile_style = {
            width: '35px',
            height: '35px',
            borderRadius: '50%',
            position: 'absolute',
            right: 100,
            top: 10
        }
    
        const nav_panel = {
            left: 20,
            position: 'absolute',
            top: 100   
        }

        let loading = false;
        if (this.props.loading) {
            loading = true;
        }
        else 
        {
            loading = false;
        }
    
        const user = JSON.parse(localStorage['user'])
        const profile = user != null ? user.photoURL: '';
        console.log(user);

        let premium = false;
        if (this.user.role == this.user.roles.admin || this.user.role == this.user.roles.premium){
            premium = true;
        }

        let menu = ''
        if (this.state.candyMenu) {
            menu = "./arrows/candy-menu.png";
        }
        else 
        {
            menu = "./arrows/kebab-menu.png"
        }

        return (<div style={style} className="header-section">
            <div style={logo_style} onClick={this.reset}>
                <img  style={logo_img} src={logo} />
                {loading && <span className="spinner-border" role="status" 
                    style={{ position: 'relative', textAlign: 'center', marginTop: 10 }}>
                </span>}
            </div>
            <span className="user-message" style={{marginLeft: -100, color: "#cbbdab", fontWeight: 'bold', marginTop: 10 }}></span>
            {<img className="switch-display" style={menu_style} src={menu} onClick={this.switch} />}
            <img style={profile_style} src={profile} />
            <button onClick={window['Logout']} style={logout_style} className="btn btn-success">Logout</button>
        </div>);
    }
}   

export default Header;