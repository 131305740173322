import PwaInstallPopupIOS from 'react-pwa-install-ios';
import logo from '../../logo-full.png'

const Install_iOS = () => {
  return (
    <PwaInstallPopupIOS>
        <div  
            delay={3}
            lang="en"
            appIcon={logo}
            style= {{
                padding: '15px 30px',
                backgroundColor: '#3f4850',
                color: 'white',
                textAlign: 'left',
            }}
            >
            <div class="pwa-install-popup-ios-content">
                <div class="left">
                    <img class="appIcon" src="/static/media/logo-full.9ac11d54073d8d7aa926.png" />
                </div>
                <div class="right">
                    <div style={{ marginBottom: 10 }}>
                        INSTALL APP BELOW
                    </div>
                    <div>
                        1- Click on the share Menu
                    </div>
                    <div>
                        2- 'Add to HomeScreen'
                    </div>
                </div>
            </div>
        </div>
    </PwaInstallPopupIOS>
  );
};

export default Install_iOS;