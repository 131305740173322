import React, { Component } from "react";
import axios from 'axios'


class Opportunity extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let color = "#333333";
        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            color = "#d2d4d8";
        }

        const body = {
            margin: 5,
            marginTop: 15,
            marginBottom: 10,
            padding: 2,
            display: 'inline-flex'
        };

        function getFirstLetters(str) {
            if (str === undefined) return "NA";
            if (str == undefined) return "NA";
            if (str == "") return "NA";
            const firstLetters = str
              .split(' ')
              .map(word => word[0].toUpperCase())
              .slice(0, 2)
              .join('');
          
            return firstLetters;
        }

        let initiais = "";
        if (this.props.item.icon == "") {
            initiais = getFirstLetters(this.props.item.opportunityTitle);
        }


        return (<div style={body}>
            <div className="opportunity-row-icon" style={{ width: 50, height: 50, minWidth: 50, minHeight: 50, backgroundColor: '#ff801e', borderRadius: '50%', margin: 10, marginTop: 5, marginLeft: 'unset', textAlign: 'center', verticalAlign: 'middle' }}>
                {initiais != "" && <span style={{ fontSize: 28, textAlign: 'center', paddingTop: 20 }}>{initiais}</span>}
                {initiais == "" && <img src ={this.props.item.icon}  style={{ height: 50, width: 50, borderRadius: '50%' }}/>}
            </div>
            <div className="opportunity-row-main" style={{ minWidth: 'calc(100% - 100px)' }}>
                <div className="opportunity-row-title" style={{ color: color, fontWeight: 'bold', fontSize: 14}}>{this.props.item.opportunityTitle}</div>
                <div className="opportunity-row-data" style={{ fontSize: 12, color: color }}>
                    { this.props.item.deadline != ""  && <div style={{ marginTop: 10 }}>Deadline: {this.props.item.deadline}</div>}
                    { this.props.item.amountTitle != ""  && <div>{this.props.item.amountTitle}: ${this.props.item.amount}</div>}
                    { this.props.item.roi != null && <div style={{ fontWeight: 'bold' }}>ROI: {(this.props.item.roi * 100).toFixed(0)}%
                    
                        { this.props.item.risk != null && <span style={{ fontWeight: 'bold' }}> | Risk: {(this.props.item.risk * 100).toFixed(0)}%</span>}
                    </div>}
                    { this.props.item.opportunityDescription != "" && <div style={{marginTop: 10}}>{this.props.item.opportunityDescription}</div>}
                </div>
            </div>
            <div style={{ color: "#ff801e", textAlign: 'right', width: 80, marginTop: 20 }}>
                <a href={this.props.item.redirectUrl} target="_blank">{this.props.item.action}</a>
            </div>
        </div>)
    }
}

export default Opportunity;