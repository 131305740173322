import React, { useState, useEffect, Component } from "react";
import CircleSectionSquare from './CircleSectionSquare';
import CircleSection from './CircleSection';

function IdeaRow(props)  {
        const ideaRowStyle = {
            color: "#fff",
            paddingTop: 20,
            display: 'inline-flex',
            justifyContent: 'space-between'
        }

        const count = props.items?.length;
        const width = 100;
        const innerMargin = (props.windowWidth - (count * width)) / (count + 1) / 4;


        return (<div className='row' id={'row-' + props.index} style={ideaRowStyle}>
            {props.items?.map((row, i) => {
                let show = true;
                if (props.row.model !== undefined && props.row.model.toLowerCase() != props.activeTopic.toLowerCase()) {
                    show = false;
                }

                {console.log('data set', row)}
                return show && <CircleSection 
                    TurnOndetailsPageActive={props.TurnOndetailsPageActive} 
                    getRecommendationCallback={props.getRecommendationCallback}
                    row ={props.row} 
                    key={'circle' + i} 
                    count={props.items.length} 
                    windowWidth={props.windowWidth} 
                    innerMargin={innerMargin} 
                    index = {i} 
                    total={props.items?.length} 
                    item={row}
                    componentType = {props.componentType} 
                    titleMessage = {props.titleMessage}
                    status = {props.status}
                    criticalStatus = {props.criticalStatus}
                    isUpdateAllBubbles = {props.isUpdateAllBubbles}
                    UpdateAllBubbles = {props.UpdateAllBubbles}
                />
            })} 
        </div>
    );
}

export default IdeaRow;