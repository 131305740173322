import React, { useState, useEffect, Component } from "react";
import User from "../User";
import axios from 'axios'
import packageJson from '../../../package.json';
import userData from '../../userData.json';
import userDataAdmin from '../../userDataAdmin.json';
import Helper from "./Helper";

const api = axios.create({ "baseURL": packageJson.proxy });

class UserOnBoard extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            display: 'none',
            active: false,
            oldStatement: '',
            percentageComplete: 0,
            backgroundColor: '#ff801e',
            statements: [],
            computeStatements: [],
            loading: false, 
            values: [], 
            processingText: "",
            user: {},
            lookupData: {},
            lookupActiveData: []
        }

        this.exit = this.exit.bind(this);
        this.SearchList = this.SearchList.bind(this);
        this.ClearSearch = this.ClearSearch.bind(this);
        this.clickStatement = this.clickStatement.bind(this);
        this.editStatmentsHandler = this.editStatmentsHandler.bind(this);
        this.fetchStatmentsHandler = this.fetchStatmentsHandler.bind(this);
        this.update = this.update.bind(this);
        this.getUser = this.getUser.bind(this);
        this.autoSetupInput = this.autoSetupInput.bind(this);
        this.makeBoxActive = this.makeBoxActive.bind(this);
        this.noList = [ ".", ",", "?", ";", ":", "'", '"', "<", ">", "=", "-", "(", "*", "&", "^", "$", "#", "@", "!", "~", "`", "+", "_" ];
    }


    componentDidMount() {
        this.setState({ loading: true, processingText: 'fetching...' });
        this.getUser();
        this.autoSetupInput();

        const [ width, height ] = Helper.Window();
        this.state.windowWidth = width;
        this.state.windowHeight = height;
        this.setState({ windowWidth: width, windowHeight: height });

        setTimeout(() => {
            this.fetchStatmentsHandler();
            setTimeout(() => {
                this.fetchStatmentsHandler();
            }, 1000);
        }, 2000);
    }
    
    exit() {
        this.setState({ display: 'none', active: false });
        //this.props.TurnOffdetailsPageActive();
    }

    getUser() {
        const us = new User();
        const /** @User */ userSet = us.setUser();
        this.setState({ user: userSet });
    }

    clickStatement(e) {
        const current = e.target.value;
        this.setState({ oldStatement: current });
    }

    autoSetupInput() {
        const stateElms = document.querySelectorAll(".state");
        let values = [];
        stateElms.forEach(elm => {
            values.push({ 
                id: elm.getAttribute('data-id'),
                statement: elm.value 
            });
        });

        let fullData = userData;
        switch(this.state.user.role) {
            case 9:
                fullData = userDataAdmin;
                break;
            case 1:
                fullData = userDataAdmin;
                break;
            case 0:
                fullData = userData;
                break;
            default:
                fullData = userData;
        }

        this.setState({ values: values, lookupData: fullData });
    }

    SearchList(e) {
        let val = e.target.value != undefined ? e.target.value : e.target.value.toLowerCase();
        const lookups = document.querySelectorAll(".lookup");

        lookups.forEach(x => {
            let elm = x.querySelector(".child-item");
            let text = elm.textContent.toLowerCase();
            if (val == "" || text.includes(val.toLowerCase())) {
                x.style.display = "";
                x.classList.remove("hidden");
            }
            else 
            {
                x.style.display = 'none';
                x.classList.add("hidden");
            }
        });

        const lookupSection = document.querySelectorAll(".lookup-section");

        lookupSection.forEach(x => {
            let elms = x.querySelectorAll(".child-item");
            let hiddens = x.querySelectorAll(".hidden");

            if (elms.length == hiddens.length) {
                x.style.display = "none";
            }
            else 
            {
                x.style.display = "";
            }
        });
    }

    ClearSearch() {
        const search = document.querySelector(".search");
        search.value = '';

        const lookups = document.querySelectorAll(".lookup");
        lookups.forEach(x => {
            x.style.display = "";
            x.classList.remove("hidden");
        });

        const lookupSection = document.querySelectorAll(".lookup-section");

        lookupSection.forEach(x => {
            x.style.display = "";
        });
    }

    makeBoxActive(e) {
        if (!e.target.classList.contains('active')) {
            e.target.classList.add('active');
            e.target.classList.add('hasid');            
            e.target.style.backgroundColor = "#0f73ad";
            e.target.style.color = "#fff";
        }
        else 
        {
            e.target.classList.remove('active');
            e.target.classList.add('remove');
            e.target.classList.remove('hasid');  
            e.target.style.backgroundColor = "#1b272e";
            e.target.style.color = "#d2d4d8";
        }

        this.update();
        this.editStatmentsHandler(e);
    }

    editStatmentsHandler(e) {
        const currentStatement = e.target.value;
        if (this.state.oldStatement == currentStatement) {
            console.log('current state did not change');
            return;
        } 

        if (this.state.percentageComplete > 1) {
            return;
        }

        console.log('haha go');

        this.update();

        let statementList = [];
        const userid = localStorage['userid'];
        let axiosConfig = {
          headers: {
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                "Host": "https://us-central1-cofounderapi.cloudfunctions.net/",
                "Origin": "https://cofounderaiai.com",
                "Referer": "https://us-central1-cofounderapi.cloudfunctions.net/"
            }
        };

        let valueset = [];
        if (e.target.classList.contains('hasid')) {
            let item = e.target.querySelector('.child-item');

            console.log(item);
            valueset.push({ 
                id: e.target.getAttribute('data-id'),
                statement: item.textContent 
            });
        }

        if (e.target.classList.contains('remove')) {
            valueset.push({ 
                id: e.target.getAttribute('data-id'),
                statement: ''
            }); 
        }

        if (e.target.classList.contains('state')) {
            if (e.target.value != "" && (e.target.value.length < 6 || (e.target.value.split(" ")).length < 3)) {
                alert("😳Do me a favor and fill in a full sentence please.");
                return;
            }

            valueset.push({ 
                id: e.target.getAttribute('data-id'),
                statement: e.target.value 
            });
        }
        
        valueset.forEach(x => {
            if (x.id != '' || x.statement != '') {
                statementList.push(x);
            }
        })

        api({
            method: 'post',
            url: "/edit-thought",
            headers: axiosConfig,
            data: { statements: JSON.stringify(statementList), user: JSON.stringify(this.state.user) }
        }).then(response => { 
            this.fetchStatmentsHandler();
        });
    }


    fetchStatmentsHandler() {
        let axiosConfig = {
          headers: {
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                "Host": "https://us-central1-cofounderapi.cloudfunctions.net/",
                "Origin": "https://cofounderaiai.com",
                "Referer": "https://us-central1-cofounderapi.cloudfunctions.net/"
            }
        };

        const userset = JSON.stringify(this.state.user);
        const states = document.querySelectorAll(".state");
        this.setState({ processingText: "fetching..." });

        api({
            method: 'post',
            url: "/fetch_thoughts",
            headers: axiosConfig,
            data: { user: userset },
        }).then(response => { 
            let obj = response.data;
            let stateElms = obj.data;
            let statesk = [];
            stateElms.forEach(elm => {
                if (elm.isActive) {
                    statesk.push(elm);
                }
            });

            stateElms = statesk;

            let newStatements = [];
            let values = [];

            stateElms.forEach(elm => {
                if (elm.isActive) {
                    values.push({ 
                        id: elm.id,
                        statement: elm.statement
                    });
                }
            });

            console.log(values);


            values = values.map(x => {
                if (x.id != '' || x.statement != '') {
                    return x;
                }
            });

            let dontTypeList = [];
            let lookupActiveData = [];
            const lookupDataList = document.querySelectorAll(".lookup");
            console.log("log it:", lookupDataList.length);
            lookupDataList.forEach((elmi, index) => {
                let elm = elmi.querySelector('.child-item');
                values.forEach(x => {
                    if (elm.textContent.toLowerCase() == x.statement.toLowerCase() && x.statement != ""){
                        dontTypeList.push(x.id);
                        lookupActiveData.push(x);
                        newStatements.push(x.statement);
                    }
                });
            });

            this.setState({ lookupActiveData: lookupActiveData });


            let count = 0;
            stateElms.forEach(elm => {
                if (elm.statement != '') {
                    count++;
                }
            });

            let percentageComplete = count / 15 > 1 ? 1 : count / 15; // then number of total textb boxes

            if (percentageComplete > 0.95) {
                this.setState({ backgroundColor: "#327e0b"});
            }
            else 
            {
                this.setState({ backgroundColor: "#ff801e"});
            }
    

            // This section we are adding to exising buttons of user data
            const rows = document.querySelectorAll(".state");
            rows.forEach((row, idx) => {
                row.value = '';
                row.innerHTML = '';
                row.setAttribute('data-id', '');
            });

            let localIndex = 0;
            
            let computeStatements = [];
            values.map(item => {                  
                if (!dontTypeList.includes(item.id) && item.statement != "") {
                    let elm = rows[localIndex];
                    console.log(elm);
                    elm.innerHTML = item.statement;
                    elm.value = item.statement;
                    elm.setAttribute('data-id', item.id);
                    newStatements.push(item.statement);
                    localIndex++;
                    computeStatements.push(item.statement);
                }
            });

            let remain = states.length - values.length;
            if (remain < 0) remain = 0;
            
            Object.keys(this.state.lookupData).map((key, index) => {
                let heading = key.replace("_", " ");
                heading = heading.charAt(0).toUpperCase() + heading.slice(1);
                let statements = this.state.lookupActiveData.map(x => { return  x.statement.toLowerCase() });
                this.state.lookupData[key].map(x => {
                    if (statements.includes(x.toLowerCase())) {                   
                        computeStatements.push(heading.toLowerCase() + " " + x.toLowerCase())  
                    }
                })
            });

            console.log(computeStatements);
            this.setState({ computeStatements: computeStatements });
        
            this.setState({ processingText: "", percentageComplete: percentageComplete });
            this.setState({ statements: newStatements,  loading: false, values: values, percentageComplete: percentageComplete, processingText: "" });
            this.props.getStatementsFromUserIdeas(computeStatements);
        });
    }



    
    update() {
        setTimeout(() => {
            const Lookup = document.querySelectorAll(".lookup.active");
            const Remove = document.querySelectorAll(".lookup.remove");

            this.setState({ statements: [] });
            let statementList = [];

            Lookup.forEach(elm => {
                console.log(elm.textContent);
                elm.text = elm.textContent.replace(/(([^\s]+\s+){10}).+/, "$1");
                if (elm.textContent == '') return;

                let text = elm.textContent;
                this.noList.forEach(nope => {
                    text = text.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g,' ').trim();
                });
                statementList.push(text);
            });
        }, 50);
    }




    render() {
        if (this.props.active && ! this.state.active) {
            this.setState({ display: 'block', active: true  });
        }

        if (!this.props.active && this.state.active) {
            this.setState({ display: 'none', active: false });
        }
    
        let body = {
            display: '', //this.state.display,
            height: this.state.windowHeight - 70,
            width: this.state.windowWidth,
            backgroundColor: '#141f25',
            padding: 20,
            position: 'fixed',
            zIndex: 80,
            top: 0,
            overflowY: 'auto'
        }

        let header = {
            display: '', //this.state.display,
            height: 100,
            width: this.props.windowWidth,
            backgroundColor: '#141f25',
            padding: 20,
            position: 'fixed',
            zIndex: 81,
            top: 0
        }
    
        const closeStyle = {
            color: '#2e3b41',
            fontSize: 60,
            position: 'absolute',
            left: 20,
            top: 10
        }

        const clearSearchStyle = {
            color: '#7e878b',
            fontSize: 40,
            right: 40,
            top: 0,
            position: 'absolute'
        }
    
        const default_progress_bar_whole = {
            width: 'calc(70% - 20px)',
            height: 15,
            position: 'absolute',
            top: 58,
            left: 75,
            borderRadius: 13,
            backgroundColor: '#2e3b41'
        }
    
        console.log(this.state.percentageComplete * 100);
        const progress_bar = {
            width:  (this.state.percentageComplete * 100) + '%',
            height: 'inherit',
            borderRadius: 13,
            backgroundColor: this.state.backgroundColor
        }
    
    
        const row_style = {
            paddingTop: 40
        }
    
        const headers = {
            marginLeft: 5,
            color: '#fff',
            textAlign: 'left',
            fontSize: 18
        }

        const SearchBox = {
            marginTop: '1.1rem!important',
            padding: 24,
            paddingLeft: 40,
            paddingRight: 40,
            borderRadius: 10,
            minWidth: this.props.windowWidth - 80,
            backgroundColor: "#2e3b41",
            border: "1px solid #2e3b41",
            fontSize: 18,
            color: "#fff"
        }

        const box = {
            backgroundColor: 'transparent',
            height: 100,
            overflowX: 'auto',
            width: '100%'
        }

        const insideBox = {
            maxWidth: 500,
            display: 'inline-flex'
        }

        let boxTag = {
            padding: 10,
            paddingTop: 6,
            paddingBottom: 6,
            borderRadius: 12,
            color: '#d2d4d8',
            backgroundColor: "#",
            margin: 5,
            width: 'auto',
            minWidth: 'fit-content',
            width: 'fit-content',
            maxHeight: 50,
            fontSize: 14,
            zIndex: 2
        }

        let localStatements = this.state.lookupActiveData.map(x => { return  x.statement.toLowerCase() });

        return (<div className='on-board' style={body}>
            <div style={header}>
                <div style={closeStyle} onClick={this.exit}>&times;</div>
            </div>

            <div id = "left-top-section-bpard" className='col-md left-top-section-board'  style={{ paddingLeft: 0, paddingRight: 0 }}>
                <div style={{ marginTop: 80}}>
                    {Object.keys(this.state.lookupData).map((key, index) => {
                        let heading = key.replace("_", " ");
                        heading = heading.charAt(0).toUpperCase() + heading.slice(1);
                        return <div style={row_style} className="lookup-section">
                            <h4 style={headers}>{heading}</h4>
                            <div style={box}>
                                <div style={insideBox}>
                                    {this.state.lookupData[key].slice(0, this.state.lookupData[key].length / 2 + 1).map(x => {
                                        let classSet = "lookup";
                                        let boxTagNow = JSON.parse(JSON.stringify(boxTag));
                                        let id = '';
                                        boxTagNow.backgroundColor = "#1b272e";
                                        boxTagNow.color = "#d2d4d8"; 
                                        let add = <span></span>;
                                        let ids = this.state.lookupActiveData.map(x => { return x.id});
                                        if (!localStatements.includes(x.toLowerCase())) {
                                            add = <span  style={{ color: "#0f73ad", fontSize: 16, pointerEvents: 'none' }}> +</span>    

                                            return <div style={boxTagNow} className={classSet} data-id={id} onClick={this.makeBoxActive}>
                                                <span className="child-item" style={{ pointerEvents: 'none' }}>{x}</span>
                                                {add}
                                            </div>
                                        }

                                    })}
                                </div>
                                <div style={insideBox}>
                                    {this.state.lookupData[key].slice(this.state.lookupData[key].length / 2 + 1).map(x => {                             
                                        let classSet = "lookup";
                                        let boxTagNow = JSON.parse(JSON.stringify(boxTag));
                                        let id = '';
                                        boxTagNow.backgroundColor = "#1b272e";
                                        boxTagNow.color = "#d2d4d8"; 
                                        let add = <span></span>;
                                        let ids = this.state.lookupActiveData.map(x => { return x.id});
                                        if (localStatements.includes(x.toLowerCase())) {
                                            add = <span style={{ color: "#0f73ad", fontSize: 16, pointerEvents: 'none' }}> +</span>

                                            return <div style={boxTagNow} className={classSet} data-id={id} onClick={this.makeBoxActive}>
                                                <span className="child-item" style={{ pointerEvents: 'none' }}>{x}</span>
                                                {add}
                                            </div>
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
        );
    }
}

export default UserOnBoard;