import React, { useState, useEffect, Component } from "react";
import logo from  '../../logo_transparent_2.png' //'../../logo-full-reverse.png'

class SplashScreen extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            display: 'none'
        }
    }

    render() {
        const {innerWidth, innerHeight} = window;

        let display = "none"
        if (this.props.loading) {
            display = "block";
        }
        else 
        {
            display = "none";
        }

        let backgroundColor = "#ff801e";
        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            backgroundColor = "#141f25";
        }


        const body = {
            position:'fixed',
            display: display,
            backgroundColor: backgroundColor, /* '#ff801e', */
            width: innerWidth,
            height: innerHeight,
            padding: 30,
            zIndex: 100
        }

        const logo_style = {
            color: "#fff",
            fontSize: 15,
            position: 'absolute',
            left: "20%",
            top: '30%',
        }

        const logo_style_img = { 
            maxHeight: 250,
            mxWidth: 250
        }

        const bottom_text = {
            color: '#fff',
            fontSize: 40,
            position: 'fixed',
            bottom: 50,
            left: '27%'
        }

        return (<div style={body}>
            <a style={logo_style}><img style={logo_style_img} src={logo} /></a>
            <div style={bottom_text}>Cofounder</div>
        </div>
        );
    }
}

export default SplashScreen;