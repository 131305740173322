import React, { Component } from "react";
import Opportunity from './Opportunity';
import axios from 'axios'


class OpportunityGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let backgroundColor = "#fff";
        let color = "#202930";
        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches){
            color = "#fff";
            backgroundColor = "#202930";
        }

        const body = {
            margin: 20,
            backgroundColor: backgroundColor,
            padding: 25,
            borderRadius: 20,
            textAlign: "left",
            color: color
        };

        return (<div style={body}>
            <div className="opportunity-top-section">

            </div>
            <div style={{ fontWeight: 'bold', fontSize: 18, marginBottom: 8 }}>
                {this.props.item.title}
            </div>
            <div>
                {this.props.item.description}
            </div>
            <div className="opportunity-rows" style={{ marginTop: 10 }}>
                {this.props.item.items.slice(0, 3).map(item =>{
                    return <Opportunity item = {item} />
                })}
            </div>
        </div>);
    }
}

export default OpportunityGroup