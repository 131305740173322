import React, { useState, useEffect, Component } from "react";


class DateSectionBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: this.props.isActive? true: false,
            date: '',
            dateNumber: 0,
            dayOfWeek: 'Sun',
            isToday: false,
        }

        this.makeActive = this.makeActive.bind(this);
    }

    makeActive() {
        this.props.updateDateBoxes(this.state.date, () => {
            const isActive = this.props.isActive;// ? true: false;
            if (isActive)  {
                this.setState({ isActive: true });
            }
            else 
            {
                this.setState({ isActive: false });
            }
        });
    }

    componentDidMount() {
        const user = JSON.parse(localStorage['user'])
    
        const actualDate = this.props.date;

        const isActive = this.props.isActive;// ? true: false;

        const setDate = new Date((actualDate + " 00:00:00").replace(" ", "T"));
        let DateNumber = setDate.getDate();
        if (DateNumber < 10) {
            DateNumber = "0" + DateNumber;
        }

        if (this.props.today == this.props.date) {
            this.setState({ isToday: true });
        }

        const DayOfWeekNumber = setDate.getDay();
    
        const dateList = { 0: 'Sun', 1: 'Mon', 2: 'Tue', 3: 'Wed', 4: 'Thu', 5: 'Fri', 6: 'Sat' };
        const DayOfWeek = dateList[DayOfWeekNumber];
        this.setState({ date: actualDate, dayOfWeek: DayOfWeek, dateNumber: DateNumber });
    }


    
    render() {
        const isActive = this.state.isActive; //this.props.isActive == true ? true: false;;

        let activeBackgroundColor = '#f4f4f4';
        let activeDayOfWeekColor= "#686b75";
        let activeDateNumberColor = "#848791"; 
        let borderRadius = 6;

        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            activeBackgroundColor = "#141f25";
        }

        if (isActive) {
            activeBackgroundColor = '#ff801e';
            activeDayOfWeekColor = "#fff";
            activeDateNumberColor = "#fff";

            if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                activeBackgroundColor = '#3e414b';
                activeDayOfWeekColor = "#d2d4d8";
                activeDateNumberColor = "#d2d4d8";
            }
            
            borderRadius = 6;
        }

        let borderBottom = '';
        let borderTop = '';
        if (this.state.isToday) {
            borderBottom = "4px solid #ff801e";
        }

        if (this.state.isToday && ! isActive) {
            borderRadius = 0;
        }

        const style = {
            padding: 2,
            paddingBottom: 10,
            backgroundColor: activeBackgroundColor,
            marginLeft: 3,
            marginRight: 3,
            borderRadius: borderRadius,
            borderBottom: borderBottom,
            borderTop: borderTop
        }
    
        const DayOfWeekStyle = {
            padding: 5,
            borderRadius: 2,
            fontSize: 13,
            color: activeDayOfWeekColor
        }

        const DateNumberStyle = {
            color: activeDateNumberColor,
            fontWeight: 'bold'
        }

        return (<div style={style} className="date-section-box" onClick={this.makeActive}>
            <div style={DayOfWeekStyle}>{this.state.dayOfWeek}</div>
            <div style={DateNumberStyle}>{this.state.dateNumber}</div>
        </div>);
    }
}

export default DateSectionBox;