import logo from './logo.svg'
import './bootstrap.css';
import './App.css';

import LoginNow from './components/LoginNow';
import CofounderApp from './components/CofounderApp';
import packageJson from '../package.json';


function App() {
  let login_section = {
      top: '40%',
      left: 'calc(50% - 180px)',
      position: 'fixed',
      backgroundColor: "#fff"
  }

  const body = document.querySelector("body");

  if (packageJson.appEnv == "app") { 
    body.style.backgroundColor = "#fff";
    login_section.backgroundColor = "#fff";
    if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        login_section.backgroundColor = "#141f25";
        body.style.backgroundColor = "#141f25";
    }  
  }

  if (packageJson.appEnv == "api") {
      login_section.backgroundColor = "#fff";
      body.style.backgroundColor = "#fff";
  }

  if (packageJson.env == "prod") {
      console.log = function () {};
  }

  return (
    <div className="App">
        <div id="firebaseui-auth-container" style={login_section}></div>
        <LoginNow />
        <CofounderApp />
    </div>
  );
}

export default App;