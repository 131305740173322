import React, { useState, useEffect, Component } from "react";
import Popup from "./Popup";
import User from "../User";

class CircleSectionSquare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            displayBox: false,
            displayBoxStyle: 'none',
            selected: false,
            popup: false
        }

        this.getUser = this.getUser.bind(this);
        this.runPopup = this.runPopup.bind(this);
        this.popupButton = this.popupButton.bind(this);
        this.removePopup = this.removePopup.bind(this);
        this.getSelectedTopicsAndGoals = this.getSelectedTopicsAndGoals.bind(this);
    }

    popupButton(e) {
        if(!this.state.displayBox) {
            this.setState({ displayBox: true, displayBoxStyle: '', selected: true, popup: false });
        }
    }

    removePopup(e) {
        this.setState({ displayBox: false, displayBoxStyle: 'none', selected: false });
    }

    runPopup() {
        this.setState({ popup: true });
        this.props.UpdateAllBubbles();
    }

    getUser() {
        const us = new User();
        us.setUser();
        const /** @User */ userSet = us.getUser();
        this.setState({ user: userSet });
        return userSet;
    }

    componentDidMount() {
        this.getUser();
    }

    getSelectedTopicsAndGoals() {
        const elements = document.querySelectorAll(".lookup-section-topic");

        let topics = [];
        let Goals = [];
        elements.forEach((elm, i) => {
            const elms = elm.querySelectorAll(".lookup.active")
            elms.forEach((row, j) => {
                if (i == 0) {
                    topics.push(row.textContent);
                }

                if (i == 1) {
                    Goals.push("my goal is " + row.textContent);
                }
            });
        });

        return [topics, Goals];
    }
 
    render() {
        const mainCircle = {
            position: 'relative',
            width: 100,
            height: 100,
            marginLeft: this.props.innerMargin,
            marginRight: this.props.innerMargin
        }

        let statusColor = '7px solid #3f4850';


        if (this.state.popup) {
            statusColor = '7px solid #ff801e';
        } 

        if (this.props.isUpdateAllBubbles) {
            statusColor = '7px solid #3f4850'; 
        }
        
        switch(this.props.status) {
            case 'completed':
                statusColor = '7px solid #314e30';
                break;
            case 'unfinished':
                statusColor = '7px solid #ff801e';
                break;
            case 'default':
                statusColor = '7px solid #3f4850';
                break;
            default:
                statusColor = '7px solid #3f4850'; 
                break;
        }

        if (this.state.selected) {
            statusColor = '7px solid #8e959d';
        }

        const outerCircle ={
            width: 'inherit',
            height: 'inherit',
            borderRadius: '50%',
            border: statusColor,
            zIndex: 1,
            //boxShadow: '6px 6px 10px -1px rgba(0,0,0,0.15), 6px 6px 10px -1px rgba(225,255,255,0.7)'
        }

        let innerCircleBackground = "#3f4850";
        if (this.props.item.commit !== undefined && this.props.item.commit.Committed) {
            innerCircleBackground = "#4f784e";
        }

        const innerCircle = {
            width: 72,
            height: 72,
            borderRadius: '50%',
            backgroundColor: innerCircleBackground,
            margin: 'auto auto',
            margin: 7,
        }

        let roiStatus = '';
        let ROIColor = {
            width: 'inherit',
            height: 'inherit',
            borderRadius: '50%',
            border: roiStatus,
            zIndex: 1,
            //boxShadow: '6px 6px 10px -1px rgba(0,0,0,0.15), 6px 6px 10px -1px rgba(225,255,255,0.7)'
        }

        const opportunityCostStyle = {
            fontSize: 10,
        }

        let titleStyle = {
            color: '#d2d4d8',
            marginTop: 10,
            fontWeight: 'bold',
            zIndex: -1
        }

        if (this.props.row == -1 || this.props.row == 0) {
            titleStyle.color = "#fff"
        }


        const desc = this.props.item.description.split(' ');

        let titleDesc = desc.splice(-15).join(' ');
        titleDesc = titleDesc.charAt(0).toUpperCase() + titleDesc.slice(1) + ".";

        let description = this.props.item.description;
        description = description.charAt(0).toUpperCase() + description.slice(1);

        let list = [
            'Get Started',
            "Next Up",
            "What's Next",
            "Starting This",
            "You're Right",
            "Being This",
            "Why Now",
            "What to Do"
        ]

        
        let title = list[Math.floor(Math.random() * list.length)]
        let displayName = this.state.user.displayName;
        let name = displayName !== undefined ? displayName.split(' ').shift(): '';
        name = name + "'s";
        if (this.props.row == -1){
            title = name + ' Career';
            let [topics, goals] = this.getSelectedTopicsAndGoals();

            if (topics.length == 1) {
                title = topics[0];
            }

            if (topics.length > 1) {
                title = name + ' ' + topics[0];
                if (topics.includes('Career')) {
                    title = name + ' Career';
                }

                if (topics.includes('College')) {
                    title = name + ' College';
                }

                if (topics.includes('Investing')) {
                    title = name + ' Investments';
                }
                
                if (topics.includes('Startup')) {
                    title = name + ' Startup';
                }
            }
        }

        if (this.props.item.snippet != null && this.props.item.snippet != "") {
            title = this.props.item.snippet;
            title = title.charAt(0).toUpperCase() + title.slice(1);
        }

        let opportunityCost = 0;
        let display = 0;
        if (this.props.item.opportunityCost != null && this.props.item.opportunityCost != "" && this.props.item.opportunityCost != 0) {
            opportunityCost = (this.props.item.opportunityCost).toFixed(2);
            display = opportunityCost; // 1.5 * Math.log10(Math.log10(Math.abs(opportunityCost * 100)));

            if (display > 0.95){
            }

            if (opportunityCost < 0) {
                roiStatus = "#f96f61";
                display = opportunityCost; //-0.25 * Math.log10(Math.log10(Math.abs(opportunityCost * 100)));
            }

            if (opportunityCost >= 0) {
                roiStatus = "#ff801e";
            }

            if (opportunityCost >= 0.15) {
                roiStatus = "#4f784e";
            }

            opportunityCost = opportunityCost; //display
            ROIColor.border = roiStatus;
        }

        title = title.replace(" | ", " ").replace("|", "").replace(" |", "").replace('"', "").replace(',', "").replace('&', "").replace("/", "").trim();
        title = title.split(".")[0];
        title = title.trim();

        let imgComponent = false;
        if (this.props.item.img!= undefined && this.props.item.img != '' && this.props.item.img != null ) {
            imgComponent = true;
        }

        let videoComponent = false;
        if (this.props.item.img!= undefined && this.props.item.video != '' && this.props.item.video != null ) {
            videoComponent = true;
        }        
        
        const rotation = 2 * 46 * 3.1415 - Math.round(2 * 46 * 3.1415 * display);  // 289 full. look at app.css  @ circle.idea { }

        return (<div id={this.props.index} row={this.props.row} key={this.props.index} onClick={this.popupButton} onMouseLeave={this.removePopup} style={mainCircle}>
            <div className="outer-circle" style={outerCircle}>
                <div className="inner-circle" style={innerCircle}>
                    <div style={{ marginBottom: 20 }}>
                    { this.props.componentType == "useridea" && imgComponent && <img style={{ position: 'absolute', height: 72, width: 72, borderRadius: '50%', marginLeft: -35, marginTop: -85, marginBottom: 40, backgroundColor: 'transparent' }} src={this.props.item.img} />}
                    </div>
                </div>
            </div>
            <div style={titleStyle}>{title} { <span style={opportunityCostStyle}> {(opportunityCost * 100).toFixed(0)}%</span>}</div>
            <div className="snippet-section" style={{ display: this.state.displayBoxStyle, zIndex: 50 }}>
                <Popup 
                    TurnOndetailsPageActive={this.props.TurnOndetailsPageActive} 
                    getRecommendationCallback={this.props.getRecommendationCallback} 
                    item = {this.props.item} 
                    count = {this.props.count} 
                    index = {this.props.index} 
                    row = {this.props.row}
                    windowWidth = {this.props.windowWidth} 
                    componentType = {this.props.componentType}
                    titleMessage = {this.props.titleMessage}
                    runPopup = {this.runPopup}
                />
            </div>
        </div>
        );
    }
}

export default CircleSectionSquare;