import React, { useState, useEffect, Component } from "react";

class People extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const stepBody = {
            marginTop: 10,
            marginBottom: 0,
            borderRadius: 10,
            border: '1px solid #3f4850',
            padding: 10,
            paddingLeft: 20,
            borderLeft: "10px solid #ff801e",
            backgroundColor: "#1d262e",
            color: '#fff',
        }

        const stepItem = {
            paddingRight: 10,
            color: "#8c959d"
        }

        return (<div className="step1" style={stepBody}>
                    <a href={"https://www.google.com/search?q=" + this.props.people.name + " " + this.props.title } 
                            style={ {color: "#fff" }} target="_blank" >
                        <div className="step-title">{this.props.people.name}: {this.props.snippet}</div>
                        <div style={{ display: "inline-flex" }}>

                        </div>
                    </a>
                </div>
        );
    }
}

export default People;