import React, { useState, useEffect, Component } from "react";
import Recommend from './Recommend'
import Step from "./Step";
import People from "./People";
import User from "../User";
import axios from 'axios'
import packageJson from '../../../package.json';
import JSConfetti from 'js-confetti'

const api = axios.create({ "baseURL": packageJson.proxy });

class DetailsPageIdea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoTitle: '',
            buttonColor: '#2e3b41',
            videoPlaying: false,
            blurred: false,
            why: true,
            user: {}
        }

        this.getUser = this.getUser.bind(this);
        this.stopVideo = this.stopVideo.bind(this);
        this.runVideo = this.runVideo.bind(this);
        this.ToggleIdeaActive = this.ToggleIdeaActive.bind(this);
        this.sideButtonWhyActive = this.sideButtonWhyActive.bind(this);
    }

    componentDidMount() {
        this.getUser();
    }


    getUser() {
        const us = new User();
        const /** @User */ userSet = us.setUser();
        this.setState({ user: userSet });
    }

    stopVideo(e) {

    }

    sideButtonWhyActive(e) {
        if (! e.target.classList.contains("active")) {
            e.target.classList.add('active');
            e.target.style.backgroundColor = "#0f73ad";
            e.target.style.color = "#fff";
            this.setState({ why: true });
        }
        else
        {
            e.target.classList.remove('active');
            e.target.style.backgroundColor = "#2e3b41";
            e.target.style.color = "#d2d4d8";
            this.setState({ why: false });
        }
    }

    runVideo(e) {
        e = e || window.event;

        let video = '';
        if (this.props.recommendation?.video != null && this.props.recommendation?.video != "") {
            video = this.props.recommendation?.video;
        }

        if (video != '') {
            this.props.openVideo(video);
        }
    }

    ToggleIdeaActive() {
        let axiosConfig = {
            headers: {
               'Accept': 'Token',
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
                  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                  "Host": "https://us-central1-cofounderapi.cloudfunctions.net/",
                  "Origin": "https://cofounderaiai.com",
                  "Referer": "https://us-central1-cofounderapi.cloudfunctions.net/"
              }
          };
  
          let userp = { uid: this.state.user.uid }
          let url = "/update-ai-idea?uid=" + this.state.user.uid + "&id=" + this.props.recommendation.id + "&index=" + this.props.index;
          console.log(url);
          api({
              method: 'get',
              url: url, 
              headers: axiosConfig,
              data: { uid: this.state.user.uid, id: this.props.recommendation.id, index: this.props.index }
          }).then(response => { 
                this.props.fetchAIResponseHandler(() => {
                    this.props.turnOff();
                    // setTimeout(() => {
                    //     this.props.turnOn();
                    // }, 800);  
                }); 
          })
          .catch(reason => {
  
          });
    }


    render() {
        // const element = document.querySelector(".details=section");
        // let scrollHeight = element.scrollHeight;
        let color = "#333333";
        let backgroundColor = '#fff';
        let backgroundBubbleColor = '#ebebeb';
        let verticalSidebarColor = "#eff3f5";
        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            color = "#fff";
            backgroundColor = '#1c282e';
            backgroundBubbleColor = '#2e3b41';
            verticalSidebarColor = "#222c30";
        }

        const {innerWidth, innerHeight} = window;
        let buttonColor = '#2e3b41';
        let blur = 0; 
        if (this.props.recommendation !== undefined && this.props.recommendation.commit != undefined && !this.props.recommendation.commit.Committed) {
            blur = 10;
            buttonColor = '#ff801e';
        }

        if (this.state.blurred) {
            blur = 10;
            buttonColor = '#ff801e';
        }

        const body = {
            marginTop: 10,
            color: color,
            textAlign: "left",
            paddingBottom: 50,
            display: 'inline-flex'
        }


        const leftVerticalSidebar = {
            width: '300px', 
            borderLeft: '2px solid ' + verticalSidebarColor, 
            paddingLeft: 15 
        }

        const topSection = {
            height: 80,
            paddingTop: 20,
            paddingBottom: 20,
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 85,
            width: '100%',
            backgroundColor: backgroundColor
        }

        const snippet = {
            fontSize: 18,
            marginBottom: 18,
            color: '#5c666e',
            fontWeight: 'bold'
        }

        const roi = {
            fontSize: 18,
            marginTop: 20,
            marginBottom: 18,
            color: '#5c666e'
        }

        let sidebarWord = {
            fontSize: 12,
            marginBottom: 15,
            color: '#5c666e',
            padding: 5,       
            borderRadius: 10,
            backgroundColor: backgroundBubbleColor,
            width: 'fit-content',
            color: color
        }

        let sidebarWordHeader = {
            fontSize: 12,
            marginBottom: 25,
            color: '#5c666e',
            padding: 5,       
            width: 'fit-content',
            color: color
        }

        const title = {
            marginBottom: 10,
            marginTop: 0,
            fontSize: 24
        } 

        const timeRan = {
            fontSize: 12,
            marginBottom: 2,
            color: color
        }

        const summary = {    
            borderLeft: "10px solid #ff801e",
            marginTop: 30,
            marginBottom: 40,
            paddingLeft: 10
        }

        const detailsSteps = {
            marginBottom: 30,
            marginLeft: 40,
            "-webkit-filter": 'blur(' + blur + 'px)', /* Safari 6.0 - 9.0 */
            filter: 'blur(' + blur + 'px)'
        }

        const whoCanHelp = {
            marginBottom: 10,
            marginLeft: 40,
            marginTop: 20,
            "-webkit-filter": 'blur(' + blur + 'px)', /* Safari 6.0 - 9.0 */
            filter: 'blur(' + blur + 'px)'
        }

        const peopleWhoDidThis = {
            marginBottom: 10
        }

        const buttonStyle = {
            backgroundColor: buttonColor,
            border:  buttonColor,
            fontWeight: 'bold',
            borderRadius: 15,
            marginLeft: '6%'
        }

        const img = {
            width: innerWidth - 100,
            position: "relative",
            left: 0,   
            marginLeft: 0,
            marginTop: 40,
            marginBottom: 0,
            display: "inline-flex"
        }

        const imgItem = {
            width: innerWidth, 
            position: 'relative'
        }

        const truncateWords = {
            width: '',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop: 0
        }


        let border = "#eff3f5";
        backgroundColor = "#f4f4f4";
        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            backgroundColor= "#1d262e";
            border = "#3f4850";
        }

        const recommendMain = {
            borderRadius: 5,
            border: '1px solid ' + border,
            padding: 20,
            marginBottom: 30,
            borderRight: "10px solid #ff801e",
            backgroundColor: backgroundColor,
            color: color,
            marginTop: 20
        }

        const paragraphStyle = {
            marginBottom: 20,
            paddingLeft: 10,
            borderLeft: "8px solid #22323a",
        }


        if (this.props.recommendation != undefined && this.props.recommendation != null && this.props.recommendation.description != undefined) {

            // setTimeout(() => {
            //     const video = document.querySelector(".ytp-title-text .ytp-title-link");
            //     console.log(video);
            //     let text = video.textContent;
            //     this.setState({ videoTitle: text });
            // }, 4000);

            const desc = this.props.recommendation.description?.split(' ');

            let titleDesc = desc.splice(-5).join(' ');
            titleDesc = titleDesc.charAt(0).toUpperCase() + titleDesc.slice(1) + ".";

            let description = this.props.recommendation?.description;
            description = description.charAt(0).toUpperCase() + description.slice(1).replace("\n\n", ". ");

            if (this.props.recommendation?.title != null && this.props.recommendation?.title != "") {
                titleDesc = this.props.recommendation?.title.replace("\n\n", ". ");
                titleDesc = titleDesc.charAt(0).toUpperCase() + titleDesc.slice(1);
            }

            if (this.props.recommendation?.description != null && this.props.recommendation?.description != "") {
                description = this.props.recommendation?.description.replace("\n\n", ". ");
                description = description.charAt(0).toUpperCase() + description.slice(1);
            }

            let video = "";
            if (this.props.recommendation?.video != null && this.props.recommendation?.video != "") {
                video = this.props.recommendation?.video;
            }

            let videoTitle = this.props.recommendation.videoTitle;
            const vid = document.createElement('textarea');
            vid.innerHTML = videoTitle;
            videoTitle = vid.innerText;

            titleDesc = titleDesc.replace("\n\n", ". ").replace(" | ", " ").replace("|", "").replace(" |", "").replace('"', "").replace('" ', "").replace(' "', "").replace(',', "").replace('&', "").replace("/", "").trim();
            titleDesc = titleDesc.replace(" | ", " ").replace("|", "").replace(" |", "").replace('"', "").replace('" ', "").replace(' "', "").replace(',', "").replace('&', "").replace("/", "").trim();  
            titleDesc = titleDesc + ".";
            titleDesc = titleDesc.replace("?.", "?").replace("!.", "!")
            titleDesc = titleDesc.trim();

            let snippetText = this.props.recommendation.snippet
            snippetText = snippetText.replace("\n\n", ". ").replace(" | ", " ").replace("|", "").replace(" |", "").replace('"', "").replace('" ', "").replace(' "', "").replace(',', "").replace('&', "").replace("/", "").trim();
            snippetText = snippetText.split(".")[0];
            snippetText = snippetText.replace("..", ".").replace("?.", "?").replace("!.", "!")
            snippetText = snippetText.trim();


            let reviewStyle = JSON.parse(JSON.stringify(sidebarWord));
            reviewStyle.backgroundColor = backgroundBubbleColor;
            reviewStyle.color = color;

            let commitedStyle = JSON.parse(JSON.stringify(sidebarWord));
            let commitedText = <span>Commit To This {snippetText} Idea</span>;
            if (this.props.recommendation.commit.Committed) {
                commitedText = <span>UnCommit</span>;
                commitedStyle.backgroundColor = backgroundBubbleColor;
                commitedStyle.color = color;
            }



            let widthp = 100; //innerWidth - 300;

            let term = 'opportunity Cost';
            let baseTerm = 'ROI';
            if(this.props.recommendation.opportunityCost < 0) {
                term = 'opportunity Cost';
                baseTerm = 'RISK'
            }

            let effectiveROI = this.props.recommendation.opportunityCost;
            if (this.props.recommendation.perspectiveROI != null) {
                effectiveROI = Math.abs(3 * this.props.recommendation.perspectiveROI * 1 * this.props.recommendation.opportunityCost);
            }

            if (this.props.recommendation.overallROI != null) {
                effectiveROI = Math.abs(this.props.recommendation.overallROI);
            }


            // need to be pulled out. This is an arrow
            let dir = './arrows/';
            let imgSet = '';
            let styleSet = { marginTop: 10, width: 70, height: 100, marginLeft: -10 };
            if (this.props.recommendation.opportunityCost > 0.15) {
                imgSet = dir + 'right.png';
                styleSet.height = 70;
                styleSet.width = 50;
            }

            if (this.props.recommendation.opportunityCost <= 0.15 && this.props.recommendation.opportunityCost > 0) {
                imgSet = dir + 'top-right.png';
                styleSet.height = 70;
                styleSet.width = 50;
            }

            if (this.props.recommendation.opportunityCost < 0 && this.props.recommendation.opportunityCost > -0.1) {
                imgSet = dir + 'divert-left-dark.png';
                styleSet.height = 70;
                styleSet.width = 50;
            }

            if (this.props.recommendation.opportunityCost < -0.10) {
                imgSet = dir + 'left.png';
                styleSet.height = 70;
                styleSet.width = 50;
            }

            if (this.props.recommendation.commit !== undefined && this.props.recommendation.commit.Committed) {
                imgSet = dir + 'forward.png';
                styleSet.height = 70;
                styleSet.width = 50;
            }



            return (<div className="details-section" style={body}>
                    <div style={{ width: 35, marginRight: 20, marginTop: -20, marginLeft: -10 }}>
                        <div style={{ position: 'fixed' }}>
                            <div style={sidebarWordHeader}>
                                {imgSet != '' && <div>
                                    <img src={imgSet} style={styleSet}/>
                                </div>}
                            </div>
                            <div style={sidebarWordHeader}>
                                <div>GAME</div>
                                <div>PLAN</div>
                            </div>
                            <div style={reviewStyle} className="sidebar-word" onClick={this.sideButtonWhyActive}>Problem</div>
                            <div style={sidebarWord} className="sidebar-word">Solution</div>
                            <div style={commitedStyle} className="sidebar-word">Commit</div>
                            <div style={sidebarWord} className="sidebar-word">working</div>
                            <div style={sidebarWord} className="sidebar-word">Finished</div>
                            <div style={sidebarWord} className="sidebar-word">Portfolio</div>
                        </div>
                    </div>
                    <div style={leftVerticalSidebar}>
                        <div className="details-snippet" style={snippet}>{snippetText} / {this.props.recommendation.user_snippet}</div>
                        <div className="details-title" style={title}>{titleDesc}</div>
                        {this.props.recommendation.perspectiveROI != null && <div className="details-roi" style={roi}>Impact: <span style={{ color: color, fontWeight: 'bold' }}>{(Math.abs(this.props.recommendation.perspectiveROI) * 100).toFixed(1)}%</span></div>}
                        <div className="details-roi" style={roi}>
                            {baseTerm}: <span style={{ color: color, fontWeight: 'bold' }}>{(Math.abs(this.props.recommendation.opportunityCost) * 100).toFixed(1)}%</span>
                        </div>
                        <div className="details-roi" style={roi}>
                            {term}: <span style={{ color: color, fontWeight: 'bold'}}>{(effectiveROI * 100).toFixed(1)}%</span>
                        </div>
                        <div className="details-cost" style={roi}>Cost: <span style={{ color: color }}>${(this.props.recommendation.cost).toFixed(2)}</span></div>
                        
                        <h5 style={{ color: color, marginTop:50 }}>PROBLEM</h5>
                        {this.state.why && this.props.recommendation.query !== undefined &&<div className="initial_query">
                            <div style={recommendMain}>
                                {/* <h5>{'Problem: '} ({this.props.recommendation.user_snippet})</h5> */}
                                <div style={truncateWords}>
                                    {this.props.recommendation.query.replace("null ", " ")} 
                                </div>
                            </div>
                        </div>}
                        <h5 style={{ color: color, marginTop: 50 }}>SOLUTION</h5>
                        <div className="details-summary" style={summary}>
                            {this.props.recommendation.model !== undefined && <span style={{ fontWeight: "bold"}}>{this.props.recommendation.model}</span>}
                            <span> | {description} ...</span>
                        </div>

                        {<div className="details-steps" style={{ marginTop: 40 }}>
                            <h5 style={{ color: color }}>HOW DO I DO THIS?</h5>
                            <Step step={{ title: titleDesc, 
                                opportunityCost: this.props.recommendation.overallROI, 
                                model: this.props.recommendation.model,
                                summary: description,
                            }} title={this.props.recommendation.how} />
                        </div>}
                        {/* <h5 style={{ color: color, marginTop: 50 }}>VIDEO ON A Solution</h5> */}

                        {this.props.recommendation.runTime != null && <small style={timeRan}>{this.props.recommendation.runTime.toFixed(0)} seconds ran</small> }


                        
                        { this.props.recommendation.people.length > 0 && <div className="details-who-can-help" style={whoCanHelp}>
                        <large style={{ color: "#5c666e" }}>HOW PEOPLE DEALT WITH {snippetText.toUpperCase()}</large>
                            {this.props.recommendation.people?.map(item => { 
                                return <People people={item} title = {titleDesc} snippet = {snippetText} />
                            })}    
                        </div>}
                        <div className="details-people" style={peopleWhoDidThis}>

                        </div>
                        {/* <div style={{ "-webkit-filter": 'blur(' + blur + 'px)', filter: 'blur(' + blur + 'px)' }}>
                            <Recommend key={1} dataset={this.props.recommendation} />
                        </div> */}

                        {this.state.why && <div>
                            <Recommend key={1} dataset={this.props.recommendation} />
                        </div>}

                        <div className="details-top-section" style={topSection}>
                            <button style={buttonStyle} onClick={this.ToggleIdeaActive} className="btn btn-success">{commitedText}</button>
                        </div>
                    </div>
                </div>
            );
        }
        else 
        {
            return <div></div>
        }
    }
}

export default DetailsPageIdea;