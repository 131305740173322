import React, { useState, useEffect, Component } from "react";
import DateSectionBox from "./DateSectionBox";


class DateSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDate: '',
            setDate: '',
            startDate: "",
            dateList: []
        }

        this.updateDateBoxes = this.updateDateBoxes.bind(this);
    }

    updateDateBoxes(date, callback) {
        this.setState({ setDate: date });
        this.props.getSetDate(date);

        setTimeout(() => {
            callback();
            this.createDateList(); 
            console.log(this.state.setDate);
        }, 100);
      
    }


    componentDidMount() {
        const d = new Date();
        let month = d.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }

        let getDate = d.getDate();
        if (getDate < 10) {
            getDate = "0" + getDate;
        }
        const today = d.getFullYear() + "-" + month + "-" + getDate;
        this.setState({ currentDate: today, setDate: today });
        this.props.getSetDate(today);

        setTimeout(() => { 
            this.createDateList();
        }, 100);
    }

    createDateList() {
        const today = this.state.setDate;
        let d = new Date((today + " 00:00:00").replace(" ", "T"));
        let dayOfWeek = d.getDay();
        let subtractDays = dayOfWeek + 1;
        let startDateTime = d.getTime() - 1000 * 60 * 60 * 24 * subtractDays;
        let sd = new Date(startDateTime);

        let month = sd.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }

        let getDate = sd.getDate();
        if (getDate < 10) {
            getDate = "0" + getDate;
        }

        let startDate = sd.getFullYear() + "-" + month + "-" + getDate;
        this.setState({ startDate: startDate });
        this.setState({ dateList: [] });
        
        let listset = [];
        
        for (let i = 0; i < 9; i++) {
            let dd = new Date((startDate + " 00:00:00").replace(" ", "T"));
            let nextddTime = dd.getTime() + 1000 * 60 * 60 * 24 * i;
            let nextDateD = new Date(nextddTime);

            let month = nextDateD.getMonth() + 1;
            if (month < 10) {
                month = "0" + month;
            }
    
            let getDate = nextDateD.getDate();
            if (getDate < 10) {
                getDate = "0" + getDate;
            }

            let nextDate = nextDateD.getFullYear() + "-" + month + "-" + getDate;
            let o = {}
            o.date = nextDate;
            o.isActive = false;

            if (nextDate == this.state.setDate) {
                o.isActive = true;
            }

            console.log('set date: ', this.state.setDate);

            listset.push(o);
        }

        console.log(listset);
        this.setState({ dateList: listset });
    }

    render() {
        let backgroundColor = "#f4f4f4";
        let borderBottom = "#eff3f5";
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            backgroundColor = "#141f25";
            borderBottom = "#2e3b41";

        }

        const style = {
            height: 'height',
            padding: 5,
            paddingRight: 8,
            paddingBottom: 10,
            paddingTop: 10,
            backgroundColor: backgroundColor,
            color: "#fff",
            position: 'fixed',
            borderBottom: '2px solid ' + borderBottom,
            zIndex: 10,
            width: '100%',
            top: 50
        }
    
        const header = {
            textAlign: 'left',
            fontSize: 30,
            color: '#d1d3d5',
            fontWeight: 'bold'
        }

        const dateHeader = {
            textAlign: 'left',
            fontSize: 20,
            marginBottom: 20,
            color: '#858892',
            fontWeight: 'bold'
        }

        const dateboxsection = {
            display: 'inline-flex'
        }
    
        const user = JSON.parse(localStorage['user'])
        return (<div style={style} className="date-section">
            {/* <div style={header}>Good Morning {user['displayName'].split(" ")[0]}</div> */}
            {/* <div style={dateHeader}>Monday, June 20</div> */}
            <div style={dateboxsection}>
                {this.state?.dateList.map((dateObject, index) => {
                    return <DateSectionBox 
                        date={dateObject.date} 
                        id={'datebox-' + index} 
                        key={'datebox-' + index} 
                        isActive={dateObject.isActive} 
                        updateDateBoxes={this.updateDateBoxes} 
                        setDate={this.state.setDate}
                        today={this.state.currentDate}
                    />
                })}
            </div>
        </div>);
    }
}

export default DateSection;