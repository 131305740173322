import React, { Component } from "react";
import axios from 'axios'


class Recommend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            whiteSpace: 70
        }

        this.openBox = this.openBox.bind(this);
    }

    openBox() {
        if (this.state.open) {
            this.setState({ open: false, whiteSpace: 70 });
        }
        else 
        {
            this.setState({ open: true, whiteSpace: 'auto' });
        }
    }

    render() {

        let color = "#333333";
        let border = "#eff3f5";
        let backgroundColor = "#f4f4f4";
        let borderBar = "#e3e9ed";
        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            color = "#fff";
            backgroundColor= "#1d262e";
            border = "#ff801e";
            borderBar = "#22323a";
        }

        const truncateWords = {
            width: '',
            // whiteSpace: this.state.whiteSpace,
            overflow: 'hidden',
            height: this.state.whiteSpace,
            textOverflow: 'ellipsis',
            marginTop: 15
        }

        const recommendMain = {
            borderRadius: 5,
            border: '1px solid ' + border,
            padding: 20,
            marginBottom: 30,
            borderRight: "10px solid #ff801e",
            backgroundColor: backgroundColor,
            color: color,
            marginTop: 30
        }

        const title = {
            marginTop: 15
        }

        const paragraphStyle = {
            marginBottom: 20,
            paddingLeft: 10,
            borderLeft: "8px solid " + borderBar,
        }

        function diff(start, end) {
            var startDate = new Date(start);
            var endDate = new Date(end);
            var diff = endDate.getTime() - startDate.getTime();
            var hours = diff / 1000 / 60 / 60;
            var minutes = Math.floor(diff / 1000 / 60);
        
            // If using time pickers with 24 hours format, add the below line get exact hours

            if (hours > 24 * 30) {
                return Math.floor(hours/24/30) + " month(s) ago";
            }

            if (hours > 24 * 7) {
                return Math.floor(hours/24/7) + " week(s) ago";
            }

            if (hours > 23) {
                return Math.floor(hours/24) + " day(s) ago";
            }

            if (hours > 1) {
                return Math.floor(hours) + " hour(s) ago";
            }

            if (minutes < 60) {
                return Math.floor(minutes) + " minute(s) ago";
            }
        }

        console.log(this.props.dataset);

        if (this.props.dataset != undefined && this.props.dataset != null && this.props.dataset.description != undefined) {

            const desc = this.props.dataset.description?.split(' ');

            let titleDesc = desc.splice(-15).join(' ');
            titleDesc = titleDesc.charAt(0).toUpperCase() + titleDesc.slice(1) + ".";

            let description = this.props.dataset?.description;
            description = description.charAt(0).toUpperCase() + description.slice(1);

            if (this.props.dataset?.title != null && this.props.dataset?.title != "") {
                titleDesc = this.props.dataset?.title;    
                titleDesc = titleDesc.charAt(0).toUpperCase() + titleDesc.slice(1);
            }

            if (this.props.dataset?.longtext != null && this.props.dataset?.longtext != "") {
                description = this.props.dataset?.longtext;
                description = description.charAt(0).toUpperCase() + description.slice(1);
            }

            const descriptionList = description.split(". ");

            // let bio = this.props.dataset?.bio;
            // bio = bio.charAt(0).toUpperCase() + bio.slice(1);

            // console.log(this.props.dataset?.date);

            //let time = diff(this.props.dataset?.date, Date.now());

            return (<div style={recommendMain} onClick={this.openBox}>
                    {/* <small style={{ color: "grey", right: 0 }}>{time}</small> */}
                    <h4 style={title}>{'Long Story Short'}</h4>
                    <div style={truncateWords}>
                        {descriptionList.map(paragraph => {
                            return <div style={paragraphStyle}>{paragraph}  ...</div>
                        })} 
                    </div>
                </div>
            );
        }
        else 
        {
            return <div></div>
        }
    }
}
    

export default Recommend;