import React, { useState, useEffect, Component } from "react";

class Step extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            whiteSpace: 'auto'
        }

        this.openBox = this.openBox.bind(this);
    }

    openBox() {
        if (this.state.open) {
            this.setState({ open: false, whiteSpace: 170 });
        }
        else 
        {
            this.setState({ open: true, whiteSpace: 'auto' });
        }
    }

    render() {
        let color = "#333333";
        let border = "#eff3f5";
        let backgroundColor = "#f4f4f4";
        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            color = "#fff";
            backgroundColor= "#1d262e";
            border = "#ff801e";
        }

        const stepBody = {
            marginTop: 20,
            marginBottom: 20,
            borderRadius: 10,
            border: '1px solid ' + border,
            padding: 20,
            borderRight: "10px solid #ff801e",
            backgroundColor: backgroundColor,
            color: color,
            overflow: 'hidden',
            height: this.state.whiteSpace,
            textOverflow: 'ellipsis'
        }


        let title = this.props.step.step;
        if (this.props.step?.title != null && this.props.step?.title != "") {
            title = this.props.step?.title;         
            title = title.charAt(0).toUpperCase() + title.slice(1);
        }

        if (this.props.title != null) {
            title = this.props.title;
        }

        let cost = this.props.step?.cost;
        let roi = this.props.step?.roi;
        if (Object.keys(this.props.step).length == 0) {
            cost = 0;
            roi = 0;
        }

        let opportunityCost = 0;
        let display = 0;
        let roiStatus = "#848791";
        if (roi != null && roi != "" && roi != 0) {
            opportunityCost = 1 - (roi).toFixed(2);
            display = 1 * Math.log10(Math.log10(Math.abs(opportunityCost * 100)));
            if (display > 0.95){
            }

            if (opportunityCost < 0) {
                roiStatus = "#f96f61";
                display = -0.25 * Math.log10(Math.log10(Math.abs(opportunityCost * 100)));
            }

            if (opportunityCost >= 0) {
                roiStatus = "#ff801e";
            }

            if (opportunityCost >= 0.25) {
                roiStatus = "#75ff1e";
            }

            opportunityCost = display
        }

        const stepItem = {
            marginTop: 10,
            paddingRight: 10,
            color: roiStatus
        }

        return (<div className="step1" style={stepBody} onClick={this.openBox}>
                    <div className="step-title">
                        <pre style={{ color: color, wordWrap: "break-word", whiteSpace: "pre-wrap", fontFamily: 'arial', fontSize: 16}}>{title}</pre> {!this.state.open && <span>...</span>}
                        <div style={{ marginTop: 20 }}>   
                            <a href={"https://www.google.com/search?q=" + "define steps to: " + title } 
                            style={ {
                                color: color,
                                marginTop: 10
                            }} target="_blank">
                            More
                            </a>  
                        </div>                 
                    </div>
                    <div style={{ display: "inline-flex" }}>
                        {/* <div className="step-cost" style={stepItem}>Cost: ${cost}</div> */}
                        <div className="step-roi" style={stepItem}>ROI: {(opportunityCost * 100).toFixed(0)}%</div>
                    </div> 
                </div>
        );
    }
}

export default Step;